import React from 'react';
import { EmblaCarouselType } from 'embla-carousel';

type UseDotButtonType = {
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
};

export const useDotButton = (
  emblaApi: EmblaCarouselType | undefined,
): UseDotButtonType => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);

  const onDotButtonClick = React.useCallback(
    (index: number) => {
      if (!emblaApi) return;
      const snapListLength = emblaApi.scrollSnapList().length;

      index === 1
        ? emblaApi.scrollTo(Math.floor(snapListLength / 2))
        : index === 2
        ? emblaApi.scrollTo(snapListLength - 1)
        : emblaApi.scrollTo(0);
    },
    [emblaApi],
  );

  const onInit = React.useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList().slice(0, 3));
  }, []);

  const onSelect = React.useCallback((emblaApi: EmblaCarouselType) => {
    const snapListLength = emblaApi.scrollSnapList().length;
    const selectedScroll = emblaApi.selectedScrollSnap();
    if (snapListLength > 3) {
      if (selectedScroll === snapListLength - 1) {
        return setSelectedIndex(2);
      } else if (selectedScroll >= (snapListLength - 1) / 2) {
        return setSelectedIndex(1);
      } else if (selectedScroll < snapListLength / 2) {
        return setSelectedIndex(0);
      }
    }

    setSelectedIndex(selectedScroll);
  }, []);

  React.useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on('reInit', onInit);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  };
};

type PropType = React.PropsWithChildren<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>;

export const DotButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;

  return (
    <button type="button" {...restProps}>
      {children}
    </button>
  );
};
