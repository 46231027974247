import React from 'react';
import { Icons } from '#components/atoms';

export const checkList: {
  [key: string]: {
    items: { text: string; color?: string }[];
    classnames?: string;
    imgDesktop: string;
    imgMobile?: string;
    buttons: {
      primary?: {
        text: String;
        url: String;
        target: string;
        icon?: string;
        style?: string;
      };
      secondary?: {
        text: String;
        url: String;
        target: string;
        icon?: {
          type:string;
          fill:string;
        }
        style?: string;
      };
    };
  };
} = {
  statistics: {
    items: [
      { text: 'Cuenta con un módulo adicional de estadísticas financieras.' },
      {
        text: 'Accede a métricas de pacientes nuevos y atendidos, analizando periodos ilimitados.',
      },
      {
        text: 'Organiza tus prestaciones por profesionales y seguros médicos para una gestión más eficiente.',
      },
      { text: 'Cuenta con un módulo adicional de estadísticas financieras.' },
      { text: 'Analiza presupuestos emitidos sin límite de periodo.' },
    ],
    imgDesktop: 'statistics-experience.png',
    buttons: {
      primary: {
        text: 'Accede a tarifas',
        url: 'https://payments.bilog.com.ar',
        target: '_self',
      },
      secondary: {
        text: 'Contáctanos',
        url: '/contact',
        target: '_self',
      },
    },
  },
  auditory: {
    items: [
      {
        text: 'Asegura la instalación y configuración correcta del software desde el inicio.',
        color: 'text-violet-500',
      },
      {
        text: 'Permite la personalización del sistema según las necesidades específicas.',
        color: 'text-violet-500',
      },
      {
        text: 'Enseñamos a los usuarios las mejores prácticas para aprovechar el software.',
        color: 'text-violet-500',
      },
      {
        text: 'Identificamos soluciones tempranas de cualquier inconveniente técnico.',
        color: 'text-violet-500',
      },
    ],
    imgDesktop: 'auditory-counseling-desktop.png',
    imgMobile: 'auditory-counseling-mobile.png',
    classnames: 'py-10',
    buttons: {
      secondary: {
        text: 'Contactar asesor',
        url: 'https://wapp.ly/l/20820ZeQv',
        target: '_blank',
        icon: {
          type:"whatsapp",
          fill:'white'
        },
        style: 'bg-violet-500 md:w-2/5',
      },
    },
  },
};
