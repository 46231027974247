import React from 'react';
import { Navigation, Footer, Hero } from '#components/organisms';
import { hero } from '#mocks/hero.tsx';
import { Toaster } from '#components/molecules/toaster';

export const Layout = ({
  children,
  section,
  fill = '#3b81f6',
}: {
  children: React.ReactNode;
  section?: string;
  fill?: string;
}) => {
  return (
    <>
      <Navigation />
      {section && <Hero data={hero[section]} fill={fill} />}
      <section className="max-w-screen-2xl mx-auto">{children}</section>
      <Toaster />
      <Footer />
    </>
  );
};
