import React from 'react';
import {
  WorkWhitUs,
  Testimonial,
  ProductHighlight,
  Faqs,
  Banners,
} from '#components/organisms';
import { Layout } from '#hocs/layout';

const HomeView = () => {
  const section = 'home';

  return (
    <Layout section={section}>
      <WorkWhitUs />
      <ProductHighlight section={section} />
      <Testimonial />
      <Faqs />
      <Banners section={section} />
    </Layout>
  );
};

export default HomeView;
