import classNames from 'classnames';
import React from 'react';
import { CardT } from '#interfaces/components';

export const Card: React.FC<CardT> = ({ className, action, children }) => {
  const classContainer = classNames(
    'rounded w-auto h-auto p-5 justify-center flex',
    className,
  );
  return (
    <div onClick={action} className={classContainer}>
      {children}
    </div>
  );
};
