'use client';

import * as React from 'react';
import {
  LuCheck as Check,
  LuChevronsUpDown as ChevronsUpDown,
} from 'react-icons/lu';
import classNames from 'classnames';
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '#components/atoms';

export function Combobox({
  className,
  data,
  name,
  onChange,
  placeholder,
}: any) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <button
          type="button"
          aria-expanded={open}
          className={classNames(
            'flex h-10 border-slate-200 justify-between items-center w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            className,
          )}
        >
          {value ? (
            data.find((item: any) => item.value === value)?.label
          ) : (
            <p className="text-slate-400">{placeholder}</p>
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </button>
      </PopoverTrigger>
      <PopoverContent
        className="!w-[350px] md:!w-[330px] lg:!w-[320px] 2xl:!w-[400px]"
        align="end"
      >
        <Command>
          <CommandList>
            <CommandGroup>
              {data.map((item: any) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={(currentValue: any) => {
                    setValue(currentValue === value ? '' : currentValue);
                    setOpen(false);
                    onChange({ target: { name, value: currentValue } });
                  }}
                >
                  <Check
                    className={classNames(
                      'mr-2 h-4 w-4',
                      value === item.value ? 'opacity-100' : 'opacity-0',
                    )}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
