import React from 'react';
import { Copy } from '#components/atoms';
import { HiCheckCircle } from 'react-icons/hi2';

const itemsStatistics = [
  'Entiende mejor el comportamiento de tus pacientes.',
  'Segmenta y visualiza rápidamente tus datos.',
  'Descubre en tiempo real, prestaciones, turnos, presupuestos y mucho más.',
];

export const hero: {
  [key: string]: {
    id: string;
    title: string;
    description_desktop: string | React.ReactNode;
    description_mobile: string | React.ReactNode;
    image_mobile: string | null;
    image_desktop: string | null;
    header: string | null;
    extend_description: string | null;
  };
} = {
  auditory: {
    id: 'auditory',
    header: 'Auditorias odontológicas',
    title: 'Agiliza la Auditoría de tus prestadores',
    description_desktop:
      'Si perteneces a una obra social, gerenciadora odontológica o círculo odontológico, con nuestro software de auditorías automatizadas podrás realizar en horas, lo que antes te tomaba días.',
    description_mobile:
      'Si perteneces a una obra social, gerenciadora odontológica o círculo odontológico, con nuestro software de auditorías automatizadas podrás realizar en horas, lo que antes te tomaba días.',
    extend_description: null,
    image_mobile: 'auditory-mobile.png',
    image_desktop: 'auditory-desktop.png',
  },
  home: {
    id: 'home',
    header: null,
    title: 'Simplifica la gestión de tu clínica dental',
    description_desktop:
      'Nuestro software dental es la solución integral que transformará la administración de tu clínica. Simplifica y optimiza cada aspecto de tu práctica odontológica.',
    description_mobile:
      'Nuestro software dental es la solución integral que transformará la administración de tu clínica.',
    extend_description: null,
    image_mobile: 'home-mobile.png',
    image_desktop: 'home-desktop.png',
  },
  schedule: {
    id: 'schedule',
    header: 'Agenda',
    title: 'Tu agenda de turnos más completa',
    description_desktop:
      'Dedica menos tiempo a la planificación y más a la acción con una agenda que puedas consultar en todos lados.',
    description_mobile:
      'Dedica menos tiempo a la planificación y más a la acción con una agenda que puedas consultar en todos lados.',
    extend_description: null,
    image_mobile: 'schedule-mobile.png',
    image_desktop: 'schedule-desktop.png',
  },
  testimonials: {
    id: 'testimonials',
    header: 'Casos de éxito',
    title: 'Nuestros casos mas increíbles',
    description_desktop:
      'Descubre las fascinantes experiencias de nuestros usuarios, quienes han adoptado Bilog para revolucionar la forma en que gestionan sus consultas odontológicas.',
    extend_description:
      'Observa relatos auténticos que te inspirarán a dar el siguiente paso hacia una gestión más eficiente y efectiva en tu práctica odontológica.',
    description_mobile:
      'Dedica menos tiempo a la planificación y más a la acción con una agenda que puedas consultar en todos lados.',
    image_mobile: null,
    image_desktop: null,
  },
  reports: {
    id: 'reports',
    header: 'Informes',
    title: 'El software que gestiona todos tus informes',
    description_desktop:
      'Ten acceso a liquidaciones de auditores, y logra generar informes detallados de pacientes, prestaciones, laboratorios y pagos realizados.',
    description_mobile:
      'Ten acceso a liquidaciones de auditores, y logra generar informes detallados de pacientes, prestaciones, laboratorios y pagos realizados.',
    extend_description: null,
    image_mobile: 'reports-mobile.png',
    image_desktop: 'reports-desktop.png',
  },
  patients: {
    id: 'patients',
    header: 'Pacientes',
    title: 'Conectando sonrisas, creando confianza',
    description_desktop:
      'Descubre cómo nuestro software odontológico trasforma tu experiencia con el paciente, simplifica la gestión clínica y promueve una atención dental de calidad.',
    description_mobile:
      'Descubre cómo nuestro software odontológico trasforma tu experiencia con el paciente, simplifica la gestión clínica y promueve una atención dental de calidad.',
    extend_description: null,
    image_mobile: 'patient-mobile.png',
    image_desktop: 'patient-desktop.png',
  },
  faqs: {
    id: 'faqs',
    header: 'FAQ’s',
    title: 'Preguntas frecuentes',
    description_desktop:
      'Aquí podrás obtener respuestas a preguntas frecuentes que pueden surgir entre nuestros usuarios. Y encontrar a la vez la solución que necesitas.',
    description_mobile:
      'Aquí podrás obtener respuestas a preguntas frecuentes que pueden surgir entre nuestros usuarios. Y encontrar a la vez la solución que necesitas.',
    extend_description: null,
    image_mobile: null,
    image_desktop: null,
  },
  contact: {
    id: 'contact',
    header: 'Contactanos',
    title: '¡Queremos conocerte!',
    description_desktop:
      '¿Preguntas o sugerencias? Nos encantaría escucharlas. Escribenos y te responderemos a la brevedad.',
    description_mobile:
      '¿Preguntas o sugerencias? Nos encantaría escucharlas. Escribenos y te responderemos a la brevedad.',
    extend_description: null,
    image_mobile: null,
    image_desktop: null,
  },
  statistics: {
    id: 'statistics',
    header: 'Estadísticas',
    title: 'El único análisis que tu consulta necesita',
    description_desktop: itemsStatistics?.map((item, index) => (
      <div className="flex items-center gap-x-4 mx-4 mb-5" key={index}>
        <HiCheckCircle className="text-blue-500 size-6" />
        <Copy className="!text-2xl 2xl:!text-2xl">{item}</Copy>
      </div>
    )),
    description_mobile: itemsStatistics?.map((item, index) => (
      <div className="flex items-center gap-x-5 mb-1" key={index}>
        <HiCheckCircle className="text-blue-500 size-7" />
        <Copy className="!text-base w-full">{item}</Copy>
      </div>
    )),
    extend_description: null,
    image_mobile: 'statistics-mobile.png',
    image_desktop: 'statistics-desktop.png',
  },
  working: {
    id: 'working',
    header: 'Perdón las molestias',
    title: 'Página en construcción',
    description_desktop: '',
    description_mobile: '',
    extend_description: null,
    image_mobile: null,
    image_desktop: null,
  },
  about: {
    id: 'about',
    header: 'Nosotros',
    title: 'Somos Bilog',
    description_desktop: (
      <span>
        Creamos la compañía tecnológica líder en gestión odontológica de
        Argentina.
        <br />
        Nuestro propósito es facilitar la administración de los consultorios y
        clínicas que nos eligen como socios de trabajo cotidiano.
      </span>
    ),
    description_mobile: (
      <span>
        Creamos la compañía tecnológica líder en gestión odontológica de
        Argentina.
        <br />
        Nuestro propósito es facilitar la administración de los consultorios y
        clínicas que nos eligen como socios de trabajo cotidiano.
      </span>
    ),
    extend_description: null,
    image_mobile: null,
    image_desktop: null,
  },
  shield: {
    id: 'shield',
    header: 'Seguridad',
    title: 'Seguridad total para tu clínica Dental',
    description_desktop:
      'Tus datos están resguardados con un backup 24/7 en servidores internacionales, garantizando máxima seguridad y disponibilidad.',
    description_mobile:
      'Tus datos están resguardados con un backup 24/7 en servidores internacionales, garantizando máxima seguridad y disponibilidad.',
    extend_description: null,
    image_mobile: 'shield-mobile.png',
    image_desktop: 'shield-desktop.png',
  },
};
