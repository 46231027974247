export const purposes: { title: string; description: string }[] = [
  {
    title: 'Misión',
    description:
      'Facilitar la gestión y administración de consultorios y clínicas para que nuestros usuarios hagan foco en lo que les importa.',
  },
  {
    title: 'Visión',
    description:
      'Lo que nos motiva a diario es llegar a ser la empresa líder en innovación de la gestión y administración de la salud en LATAM.',
  },
  {
    title: 'Objetivos',
    description:
      'Nuestro objetivo es ayudar con las tareas administrativas para que los profesionales se dediquen sin preocupaciones extras a ser los mejores en su área.',
  },
];

export const history: { title: string; description: string; icon: string }[] = [
  {
    title: 'El inicio',
    description:
      'Todo comenzó en una casa. En 2005 Celeste y Aldo decidieron emprender un sueño que revolucionó la gestión de la práctica odontológica, desarrollando el primer sistema específico para el mundo de la odontología.',
    icon: 'home.png',
  },
  {
    title: 'Primeros pasos',
    description:
      'Con dos computadoras, un teléfono y un CD, Celeste y Aldo visitaron a cada uno de sus clientes instalando su sistema en consultorios y clínicas y brindando capacitaciones.Bilog se expandió por toda la Argentina.',
    icon: 'user.png',
  },
  {
    title: 'Innovación en auditoría',
    description:
      'En 2010 Bilog introdujo su primer Software de Auditoría. Este nuevo producto permitió entrar en el nicho de auditores, gerenciadoras y obras sociales, asegurando control, ahorro y eficiencia en los procesos.',
    icon: 'users.png',
  },
  {
    title: 'De generación en generación',
    description:
      'El año 2019 marcó un hito en la historia de Bilog. Los hijos de Celeste y Aldo se unieron a la empresa, asegurando la permanencia y desarrollo de esta pasión familiar; aportando sus conocimientos de primer nivel y sus experiencias en unicornios argentinos.Su gran desafío y pasión es continuar con el crecimiento de Bilog.',
    icon: 'puzzle.png',
  },
  {
    title: 'Expansión internacional',
    description:
      'En plena pandemia Bilog comenzó a comercializar su software en el exterior, llegando a países como España, Uruguay y Paraguay. Ese mismo año, lanzaron su aplicación web, ofreciendo una solución aún más accesible y moderna para sus usuarios. Su objetivo actual es sumar nuevos productos y funciones para sus clientes.',
    icon: 'rocket-launch.png',
  },
];
