import React from 'react';
import { Icons, Title, List } from '#components/atoms';
import { Logo } from '#components/molecules';
import { FooterData } from '#mocks/footer';

export const Footer: React.FC = () => {
  const onChangeRoute = (route: string) => {
    window.open(route, '_self');
  };

  return (
    <div className="bg-blue-50 px-5 absolute w-full">
      <div className="max-w-screen-2xl mx-auto lg:px-20 pt-16">
        <div className="flex justify-between items-center">
          <Logo classname="p-4" height={120} width={120} />
          <p className="text-sm text-slate-400">
            Copyright © {new Date().getFullYear()} Bilog, Inc. All rights
            reserved.
          </p>
        </div>
        <List data={FooterData} />
        <div className="grid grid-cols-2 lg:grid-cols-12 relative -top-14">
          <Title className="col-start-2 lg:col-start-10 col-span-2 p-2 ml-4 text-xl">
            Seguínos
            <span className="flex gap-5 mt-2 relative z-50">
              <Icons
                type="facebook"
                className="border-2 border-black rounded-xl p-1 h-6 w-6 cursor-pointer"
                onClick={() =>
                  onChangeRoute('https://www.facebook.com/Bilog.Soluciones')
                }
              />
              <Icons
                type="instagram"
                className="border-2 border-black rounded-xl p-1 h-6 w-6 cursor-pointer relative z-50"
                onClick={() =>
                  onChangeRoute('https://www.instagram.com/bilogsoftware')
                }
              />
              <Icons
                type="linkedin"
                className="border-2 border-black rounded-xl p-1 h-6 w-6 cursor-pointer relative z-50"
                onClick={() =>
                  onChangeRoute(
                    'https://ar.linkedin.com/in/bilog-software-odontol%C3%B3gico-94a9264b',
                  )
                }
              />
            </span>
          </Title>
        </div>
      </div>
    </div>
  );
};
