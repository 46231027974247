export const testimonialsData: {
  avatar: string;
  userName: string;
  proffesion: string;
  testimonialText: string;
}[] = [
  {
    avatar: 'testimonial-14.jpg',
    userName: 'Alejandro Correa Silva',
    proffesion: 'Odontólogo',
    testimonialText:
      '¡El software es excelente! Organiza muy bien las agendas y la facturación junto con la parte administrativa. El soporte post-venta es muy bueno, cualquier duda que te surge enseguida te lo solucionan.De verdad es muy recomendable, para mi es lejos el mejor software del mercado.',
  },
  {
    avatar: 'testimonial-13.jpg',
    userName: 'Alfredo Malano',
    proffesion: 'Odontólogo',
    testimonialText:
      'BILOG es una excelente herramienta de gestión, que nos permite estar organizados y obtener información financiera, estadística y administrativa. Es un sistema de fácil manejo, de fundamental importancia en mis consultorios.',
  },
  {
    avatar: 'testimonial-12.jpg',
    userName: 'Belén Trias',
    proffesion: 'Odontóloga',
    testimonialText:
      'Después de probar otros programas de gestión, nos decidimos por Bilog, los pasos para hacer todo se acompaña con lo que vas pensando. Nos soluciona toda la gestión de la clínica.',
  },
  {
    avatar: 'testimonial-11.jpg',
    userName: 'Gonzalo Gianera',
    proffesion: 'Odontólogo',
    testimonialText:
      'El software me ayudó a organizar la Clínica de la mejor manera posible. Es tan completo que, aún hoy, después de varios años de usarlo le sigo descubriendo utilidades que optimizan aún más el rendimiento de la Clínica.',
  },
  {
    avatar: 'testimonial-01.jpg',
    userName: 'Juan Eugenio Caride Sierra',
    proffesion: 'Odontólogo',
    testimonialText:
      'Excelente software y excelente servicio post venta, lo recomiendo en cada una de mis consultorías y lo uso en mi clínica.',
  },
  {
    avatar: 'testimonial-02.jpg',
    userName: 'Barella Marianela Alejandro',
    proffesion: 'Odontóloga',
    testimonialText:
      'Es una excelente herramienta para el manejo del consultorio, muy recomendable, ademas tienen un muy buen servicio pos venta.',
  },
  {
    avatar: 'testimonial-03.jpg',
    userName: 'Mauro Lenarduzzi',
    proffesion: 'Odontólogo',
    testimonialText:
      'Excelente software de gestión Odontologica, es súper completo y está constantemente actualizándose a las necesidades de los clientes. Facilita la la organización de pacientes, trabajos y toda la parte administrativa ya sea desde un pequeño consultorio a una gran clínica.',
  },
  {
    avatar: 'testimonial-04.jpg',
    userName: 'Edgardo Gonzalez',
    proffesion: 'Odontólogo',
    testimonialText:
      'Excelente sistema e impecable la atención del soporte técnico.',
  },
  {
    avatar: 'testimonial-05.jpg',
    userName: 'Yanina Pibernus De Buksa',
    proffesion: 'Odontóloga',
    testimonialText:
      'La verdad encantada con la atención y la predisposición para explicar todo! Muy conforme. Súper recomendable!',
  },
  {
    avatar: 'testimonial-06.jpg',
    userName: 'Carina Montero',
    proffesion: 'Odontóloga',
    testimonialText:
      'Muy buen software, muy completo. Lo que más uso y me facilita muchísimo la organización del consultorio, es poder consultar la agenda desde la app. Muy conforme!',
  },
  {
    avatar: 'testimonial-07.jpg',
    userName: 'Silvio Martín Teyra',
    proffesion: 'Odontólogo',
    testimonialText:
      'Recomendable 100% muy buen software de gestión, muy aprovechable, muy completo que permite integrar todas las áreas de la gestión del día a día, lo utilizo hace años y no he tenido inconvenientes, y las dudas que surgieron fueron rápidamente aclaradas por el servicio de atención!',
  },
  {
    avatar: 'testimonial-08.jpg',
    userName: 'Joaquin Jahke',
    proffesion: 'Odontólogo',
    testimonialText:
      'El sistema es muy bueno, tengo la versión small y me ayudo un montón a organizar mi consultorio! Gracias a todo el equipo y sobre todo a Celeste que siempre está a disposición para ayudar con las dudas que surgen! Muy completo!',
  },
  {
    avatar: 'testimonial-09.jpg',
    userName: 'Giselle Morici',
    proffesion: 'Odontóloga',
    testimonialText:
      'Excelente sistema! Practico, ágil y eficiente. Los pacientes felices con el SMS para recordarles el turno!',
  },
  {
    avatar: 'testimonial-10.jpg',
    userName: 'Marcelo Raed',
    proffesion: 'Odontólogo',
    testimonialText:
      'Un programa espectacular! pero sobre todo la excelencia en la atención desde el primer contacto. Destaco lo completo que es y la posibilidad de usarlo desde distintos aparatos en forma simultanea y en red, con actualización instantánea de los datos.',
  },
];
