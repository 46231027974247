import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import classNames from 'classnames';

export const Popover: React.FC<any> = PopoverPrimitive.Root;

export const PopoverTrigger: React.FC<any> = PopoverPrimitive.Trigger;

type PopoverContentT = {
  className?: string;
  align?: 'start' | 'end' | 'center' | undefined;
  children?: React.ReactNode;
};

export const PopoverContent = React.forwardRef<HTMLDivElement, PopoverContentT>(
  ({ className, align, ...props }, ref) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        className={classNames(
          'top-0 z-50 w-auto rounded-md shadow-md border border-slate-200 bg-white p-4 text-slate-950',
          className,
        )}
        {...props}
      />
    </PopoverPrimitive.Portal>
  ),
);

PopoverContent.displayName = PopoverPrimitive.Content.displayName;
