import React from 'react';
import { Layout } from '#hocs/layout';
import { FilterFaqs } from '#components/molecules/filter-buttons';
import { Banners } from '#components/organisms';

const FaqsView = () => {
  const section = 'faqs';

  return (
    <Layout section={section}>
      <FilterFaqs />
      <Banners section={section} />
    </Layout>
  );
};
export default FaqsView;
