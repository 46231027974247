import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Copy,
} from '#components/atoms';
import { FaqsT } from '#interfaces/components';

export const Faq: React.FC<FaqsT> = ({ data, selectFilter }) => {
  const filterData = data.filter((f) =>
    selectFilter ? f.id === selectFilter : f.show_home,
  );

  return (
    <Accordion type="single" collapsible className="w-full max-w-screen-2xl">
      {filterData?.map((f: any, index: number) => (
        <AccordionItem
          value={f.title}
          className="bg-slate-50 shadow-md"
          key={index}
        >
          <AccordionTrigger>
            <Copy className="!font-bold !text-slate-700 !text-base pl-4 pr-10 lg:!text-xl 2xl:!text-2xl">
              {f.title}
            </Copy>
          </AccordionTrigger>
          <AccordionContent>{f.description}</AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
