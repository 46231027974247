import React from 'react';
import { Layout } from '#hocs/layout';
import {
  WorkWhitUs,
  OurHistory,
  Remembers,
  FeaturedItems,
  Banners,
} from '#components/organisms';

const AboutView = () => {
  const section = 'about';

  return (
    <Layout section={section}>
      <WorkWhitUs />
      <FeaturedItems section={section} />
      <OurHistory />
      <Remembers section={section} />
      <Banners section={section} />
    </Layout>
  );
};
export default AboutView;
