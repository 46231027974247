import React from 'react';
import { HiCheckCircle } from 'react-icons/hi2';
import { Copy, Image, Title } from '#components/atoms';
import { odontogram } from '#mocks/patients';

export const Odontogram = () => {
  const { title, header, description, check_list } = odontogram || {};

  return (
    <div className="mx-5 mt-20 mb-10 lg:my-28 lg:px-24">
      <Copy className="lg:!text-xl !font-bold !text-blue-400 mb-1 lg:text-center 2xl:!text-2xl">
        {header}
      </Copy>
      <Title className="text-3xl mb-4 lg:mb-8 lg:text-center lg:text-5xl 2xl:text-6xl">
        {title}
      </Title>
      <Copy className="!text-base mb-8 lg:hidden">{description}</Copy>
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-6">
        <Image
          src={require(`#assets/products/patient-odontogram-mobile.png`)}
          alt="device-frame"
          className="w-full lg:col-span-5 object-contain"
        />
        <div className="hidden lg:block col-span-7">
          <Copy className="!text-base mb-6 mt-8 lg:!text-xl 2xl:!text-2xl">
            {description}
          </Copy>
          {check_list?.map((item, index) => (
            <div className="grid grid-cols-12 mt-8 items-start" key={index}>
              <HiCheckCircle className="text-blue-500 col-span-1 size-7" />
              <Copy className="!text-2xl col-span-10">{item}</Copy>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
