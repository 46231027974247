import React from 'react';
import { Layout } from '#hocs/layout';
import { Terms } from '#components/organisms';

const TermsView = () => {
  return (
    <Layout>
      <Terms />
    </Layout>
  );
};

export default TermsView;
