import React from 'react';
import { Tabs, TabsContent, TabsList } from '#components/atoms';
import { Faq, tabsFilter } from '#components/molecules';
import { filtersButtons } from '#mocks/extend-testimonials';
import { dataFaqs } from '#mocks/faqs';
import { useDeviceDetector } from '../../hooks';

export const FilterFaqs = () => {
  const { deviceType } = useDeviceDetector();

  return (
    <>
      <Tabs defaultValue="desktop" className="overflow-hidden lg:mx-24">
        <TabsList className="flex overflow-x-auto gap-x-4 lg:gap-x-0 overflow-y-hidden items-center justify-between !border-none !shadow-none">
          {tabsFilter(
            filtersButtons,
            deviceType !== 'desktop'
              ? 'gap-x-2 rounded-full data-[state=active]:!border-none'
              : 'gap-x-2',
          )}
        </TabsList>
        {['desktop', 'web_&_mob', 'learning', 'plans', 'limitations'].map(
          (tab) => (
            <TabsContent
              value={tab}
              key={tab}
              className="mt-7 mb-12 px-5 lg:px-0"
            >
              <Faq data={dataFaqs} selectFilter={tab} />
            </TabsContent>
          ),
        )}
      </Tabs>
    </>
  );
};
