import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CardStack, Copy } from '#components/atoms';
import { ItemCardStack } from '#components/molecules';

export const StackTestimonials = () => {
  const navigate = useNavigate();

  return (
    <div className="hidden md:flex md:flex-col lg:grid lg:grid-cols-2 pt-7 gap-8">
      <CardStack items={ItemCardStack()} />
      <div>
        <Copy className="mb-8 text-xl md:hidden lg:block 2xl:!text-2xl">
          Miles de profesionales como vos eligen y siguen eligiendo a Bilog,
          conoce sus experiencias.
        </Copy>
        <div className="flex gap-x-6 md:w-full">
          <Button
            className="w-auto h-12 lg:col-span-4 lg:h-10"
            onClick={() => navigate('/contact')}
          >
            Cuéntanos tu experiencia
          </Button>
        </div>
      </div>
    </div>
  );
};
