export const features: {
  title: string;
  description: string;
  platforms: {
    title: string;
    icon: string;
    classname: string;
    fill: string;
  }[];
}[] = [
  {
    title: 'Liquidación de profesionales',
    description:
      'Permite ver prestaciones realizadas, los tipos de pago que pueden liquidarse y los totales de laboratorio.',
    platforms: [
      {
        title: 'Escritorio',
        icon: 'desktop',
        classname: '!text-green-600 !bg-green-50',
        fill: '#ffff',
      },
    ],
  },
  {
    title: 'Liquidación de obras sociales / Seguros médicos',
    description:
      'Ofrece opciones de filtrado por obras sociales, grupos, profesionales y periodos.',
    platforms: [
      {
        title: 'Móvil',
        icon: 'mobile',
        classname: '!text-amber-600 !bg-amber-50',
        fill: '#ffff',
      },
      {
        title: 'Web',
        icon: 'world',
        classname: '!text-violet-600 !bg-violet-50',
        fill: '#ffff',
      },
      {
        title: 'Escritorio',
        icon: 'desktop',
        classname: '!text-green-600 !bg-green-50',
        fill: '#ffff',
      },
    ],
  },
  {
    title: 'Informes de pacientes',
    description:
      'Acceso a lista de pacientes ingresados. Información detallada sobre las prestaciones realizadas, cumpleaños y más.',
    platforms: [
      {
        title: 'Web',
        icon: 'world',
        classname: '!text-violet-600 !bg-violet-50',
        fill: '#ffff',
      },
    ],
  },
  {
    title: 'Liquidación de laboratorios y auditorias',
    description:
      'Visualiza los pendientes de entrega, los montos totales del laboratorio, incluyendo pedidos realizados, recibidos y pagados.',
    platforms: [
      {
        title: 'Web',
        icon: 'world',
        classname: '!text-violet-600 !bg-violet-50',
        fill: '#ffff',
      },
    ],
  },
  {
    title: 'Liquidación de auditor',
    description:
      'Ofrece opciones de filtrado por obras sociales, grupos, profesionales y periodos.',
    platforms: [
      {
        title: 'Móvil',
        icon: 'mobile',
        classname: '!text-amber-600 !bg-amber-50',
        fill: '#ffff',
      },
      {
        title: 'Web',
        icon: 'world',
        classname: '!text-violet-600 !bg-violet-50',
        fill: '#ffff',
      },
      {
        title: 'Escritorio',
        icon: 'desktop',
        classname: '!text-green-600 !bg-green-50',
        fill: '#ffff',
      },
    ],
  },
];

export const FEATURES_CAROUSEL: {
  img: string;
  badge: string;
  classnames: string;
  title: string;
  description: string;
}[] = [
  {
    title: 'Optimiza el trabajo de tus auditores',
    description:
      'Con tan solo un botón puedes auditar las prestaciones de tus profesionales, para reducir significativamente el esfuerzo manual de tus prestadores.',
    img: 'auditory-feature-mobile-01.png',
    badge: 'Automatización de procesos',
    classnames: '!text-amber-600 !bg-amber-50',
  },
  {
    title: 'Garantiza cumplimiento de reglas',
    description:
      'Un conjunto de criterios y procedimientos predefinidos que se utilizan para evaluar y monitorear la calidad, cumplimiento y eficiencia de las prácticas odontológica.',
    img: 'auditory-feature-mobile-02.png',
    badge: 'Cumplimiento de normativas',
    classnames: 'text-violet-600 bg-violet-50',
  },
  {
    title: 'Conoce el estado de tus afiliados',
    description:
      'Visualiza de manera clara en las tablas las prestaciones rechazadas y aceptadas conforme al estado del afiliado y las reglas establecidas.',
    img: 'auditory-feature-mobile-03.png',
    badge: 'Marcador visual de prestaciones',
    classnames: 'text-green-600 bg-green-50',
  },
  {
    title: 'Súmate a la conciencia ecológica',
    description:
      'Gracias a que todos los procesos se digitalizan y gestionan en la nube, disminuye el uso de papel y de emisión de carbono.',
    img: 'auditory-feature-mobile-04.png',
    badge: 'Reducción de huella ambiental',
    classnames: 'text-pink-600 bg-pink-50',
  },
];

export const items: {
  badge: string;
  description: string;
  className: string;
  img: string;
}[] = [
  {
    badge: 'Obras sociales',
    description:
      'Organizaciones encargadas de la administración y financiación de servicios de salud.',
      className: '!text-amber-400 !bg-amber-50',
    img: 'auditory-card1.png',
  },
  {
    badge: 'Gerenciadores y círculos',
    description: 'Entidades que financian y supervisan los servicios de salud de sus afiliados.',
    className: 'text-violet-600 bg-violet-50',
    img: 'auditory-card2.png',
  },
  {
    badge: 'Prestadores',
    description:
      'Persona física o jurídica, que posee la autorización de otorgar prestaciones de salud.',
    className: 'text-green-600 bg-green-50',
    img: 'auditory-card3.png',
  },
];