import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Copy, Icons, Image, Title } from '#components/atoms';
import { stories } from '#mocks/stories';

export const ItemCardStack = () => {
  const navigate = useNavigate();

  return stories?.map((story, index) => {
    return {
      id: index,
      content: (
        <div className="flex flex-row w-full" key={index}>
          <Image
            src={require(`#assets/succes-stories/stacks/${story.img}.webp`)}
            alt="device-frame"
            className="w-36 h-60 mb-4 rounded-s-lg"
          />
          <div className="p-5">
            <Title className="text-lg mb-5 !text-slate-900">
              {story.title}
            </Title>
            <Copy className="text-sm !text-slate-500 mb-5">
              {story.description}
            </Copy>
            <Button
              modifier="expansion"
              asChild
              onClick={() => navigate(`/testimonials/${story.id}`)}
            >
              <p className="text-blue-500 text-left text-base font-normal whitespace-nowrap group-hover:font-bold pr-4 py-2">
                Ver más
              </p>
              <Icons
                className="text-blue-500 h-4 font-regular group-hover:stroke-[3]"
                type="outlineArrowRight"
              />
            </Button>
          </div>
        </div>
      ),
    };
  });
};
