import React from 'react';
import { FaLocationArrow } from 'react-icons/fa6';
import { Copy, Image, Title } from '#components/atoms';

export const Timeline = ({
  data,
}: {
  data: { title: string; description: string; icon: string }[];
}) => {
  return (
    <div className="w-auto lg:mx-44 relative hidden md:block">
      <div className="absolute left-[27px] top-0 bottom-0 w-1 h-full bg-gradient-to-b from-blue-50 to-blue-500" />
      <FaLocationArrow className="absolute rotate-[135deg] -bottom-3 left-[15px] size-7 text-blue-500" />
      <ul className="flex w-full flex-col">
        {data?.map((item) => (
          <Item
            title={item.title}
            description={item.description}
            icon={item.icon}
          />
        ))}
      </ul>
    </div>
  );
};

const Item = ({
  title,
  description,
  icon,
}: {
  title: string;
  description: string;
  icon: string;
}) => {
  return (
    <li className="relative flex flex-col gap-2 mb-4">
      <div className="flex items-start gap-4">
        <Image
          src={require(`#assets/icons/${icon}`)}
          width={400}
          height={300}
          alt="product-image"
          className="relative z-[2] size-14 flex-shrink-0 overflow-hidden rounded-full shadow-md items-center justify-center flex text-white"
        />
        <div>
          <Title>{title}</Title>
          <div className="flex mt-3 mb-4">
            <span className="pointer-events-none invisible h-full flex-shrink-0" />
            <Copy className="!text-base !text-slate-500">{description}</Copy>
          </div>
        </div>
      </div>
    </li>
  );
};
