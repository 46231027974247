import React from 'react';
import { Layout } from '#hocs/layout';
import {
  Doubts,
  ExperienceImprove,
  OtherSections,
  ProductHighlight,
  Remembers,
} from '#components/organisms';

const StatisticsView = () => {
  const section = 'statistics';

  return (
    <Layout section={section}>
      <ProductHighlight section={section} />
      <Remembers
        header="Crecimiento"
        section={section}
        title="Nuestros datos no se equivocan"
      />
      <Doubts selectFilter="web_&_mob"/>
      <ExperienceImprove 
      section={section}
      header="Mejora tu experiencia"
      title="Activa estadísticas exclusivas en Bilog"
      description="Nuestra sección de estadísticas te permite conocer
            métricas especializadas y personalizadas para tu consultorio,
            facilitando estrategias adaptadas a tu negocio y favoreciendo su
            crecimiento."
      />
      <OtherSections />
    </Layout>
  );
};

export default StatisticsView;
