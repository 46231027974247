import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Copy, Icons, Title } from '#components/atoms';
import { otherSections } from '#mocks/schedule';

export const OtherSections = () => {
  const navigate = useNavigate();

  return (
    <Card className="flex flex-col py-10 lg:px-24">
      <Title className="text-3xl lg:text-5xl lg:text-center !font-bold mb-7 2xl:!text-6xl">
        Otras secciones que pueden ser de interés
      </Title>
      <Copy className="!font-regular text-xl mb-7 text-start lg:text-center 2xl:text-2xl">
        Conoce más acerca del software odontológico más elegido en LATAM.
      </Copy>
      <div className="lg:flex lg:space-x-6 lg:justify-center">
        {otherSections.map((item: any, index: number) => (
          <div
            className="p-5 rounded-sm shadow-md mb-5 lg:mb-0 w-full"
            key={index}
          >
            <div className="flex items-center space-x-2 mb-2">
              <Icons
                type={item.icon}
                className="size-9 text-blue-400"
                fill="white"
              />
              <Title className="!font-bold text-xl !text-slate-900">
                {item.title}
              </Title>
            </div>
            <Copy className="mb-2">{item.description}</Copy>
            <Button
              modifier="expansion"
              asChild
              onClick={() => navigate(item.link)}
            >
              <p className="text-blue-500 text-left text-base font-regular whitespace-nowrap group-hover:font-bold pr-2">
                Ver más
              </p>
              <Icons
                className="text-blue-500 h-4 font-regular group-hover:stroke-[3]"
                type="outlineArrowRight"
              />
            </Button>
          </div>
        ))}
      </div>
    </Card>
  );
};
