import React from 'react';
import classNames from 'classnames';
import {
  Badge,
  Carousel,
  CarouselContent,
  CarouselItem,
  Copy,
  Image,
  Title,
} from '#components/atoms';
import { FEATURES_CAROUSEL } from '#mocks/auditory';
import { useDeviceDetector } from '../../hooks';

// TODO: Este componente se tiene que abstraer ya
// que en pacientes de está duplicando.
export const AuditoryFeatures = () => {
  const { MobileView, TabletView, DesktopView } = useDeviceDetector();

  return (
    <div id="auditoryFeatures" className="m-5 py-10 lg:py-12 lg:mx-20">
      <Copy className="!text-violet-400 mb-1 !text-base lg:text-center lg:!text-xl !font-bold 2xl:!text-xl">
        Beneficios
      </Copy>
      <Title className="text-3xl mb-8 lg:mb-8 lg:text-5xl lg:text-center 2xl:!text-6xl">
        Descubre todo lo que puedes lograr
      </Title>
      <MobileView>
        <Carousel autoPlay className="pt-2">
          <CarouselContent>
            {FEATURES_CAROUSEL.map((item, index) => (
              <CarouselItem
                key={index}
                className="!basis-5/6 md:!basis-2/3 lg:!basis-[24%] max-w-lg"
              >
                <Items
                  title={item.title}
                  description={item.description}
                  img={item.img}
                  badge={item.badge}
                  classnames={item.classnames}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </MobileView>
      <TabletView>
        <Carousel autoPlay className="pt-2">
          <CarouselContent>
            {FEATURES_CAROUSEL.map((item, index) => (
              <CarouselItem
                key={index}
                className="!basis-5/6 md:!basis-2/3 lg:!basis-[24%] max-w-lg"
              >
                <Items
                  title={item.title}
                  description={item.description}
                  img={item.img}
                  badge={item.badge}
                  classnames={item.classnames}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </TabletView>
      <DesktopView>
        <div className="grid grid-cols-2 gap-6">
          {FEATURES_CAROUSEL.map((item, index) => (
            <Items
              key={index}
              title={item.title}
              description={item.description}
              img={item.img}
              badge={item.badge}
              classnames={item.classnames}
            />
          ))}
        </div>
      </DesktopView>
    </div>
  );
};

const Items = ({
  img,
  title,
  description,
  badge,
  classnames,
}: {
  img: string;
  title: string;
  description: string;
  badge: string;
  classnames: string;
}) => {
  return (
    <div className="p-3">
      <Image
        src={require(`#assets/products/${img}`)}
        alt="device-frame"
        className="w-full object-contain mb-4"
      />
      <Badge
        className={classNames('mb-4 lg:!text-base 2xl:!text-2xl', classnames)}
      >
        {badge}
      </Badge>
      <Title className="text-base lg:text-xl 2xl:text-2xl">{title}</Title>
      <Copy className="mb-16 lg:!text-base 2xl:!text-xl">{description}</Copy>
    </div>
  );
};
