export const menuData: {
  id: number;
  title: string | null;
  titleDesktop: string | null;
  subTitle: string | null;
  titlesContainMenu:
    | { title: string; description: string; id: number }[]
    | null;
  isNotshowInDesktop: boolean;
  details:
    | {
        id?: number;
        title: string;
        subTitle: string;
        icon?: string;
        logo?: string;
        fill?: string;
        navigation: string;
      }[]
    | null;
  navLink: string | null;
}[] = [
  {
    id: 1,
    isNotshowInDesktop: false,
    navLink: null,
    title: 'Gestión odontológica',
    titleDesktop: 'Productos',
    subTitle:
      'Centraliza el control de tu clínica  para una gestión más eficiente',
    titlesContainMenu: [
      {
        title: 'Gestión odontológica',
        description:
          'Centraliza el control de tu clínica  para una gestión más eficiente.',
        id: 1,
      },
      {
        title: 'Auditoria odontológica',
        description:
          'Auditá las liquidaciones de prestadores según tus reglas predefinidas.',
        id: 2,
      },
    ],
    details: [
      {
        title: 'Agenda',
        subTitle: 'Organiza los turnos de forma eficiente.',
        icon: 'calendar',
        fill: 'white',
        navigation: '/schedule',
      },
      {
        title: 'Pacientes',
        subTitle: 'Gestiona toda su información desde un mismo lugar.',
        icon: 'user',
        fill: 'white',
        navigation: '/patients',
      },
      {
        title: 'Estadísticas',
        subTitle: 'Lleva un registro de tus finanzas.',
        icon: 'statistics',
        fill: 'white',
        navigation: '/statistics',
      },
      {
        title: 'Informes',
        subTitle: 'Encuentra los informes de tus pacientes y más.',
        icon: 'document',
        fill: 'white',
        navigation: '/reports',
      },
      // {
      //   title: 'Administración',
      //   subTitle:
      //     'Maneja los ingresos y egresos, gestiona y controla todos los pagos de tu clínica.',
      //   icon: 'currency',
      //   fill: 'white',
      // },
      {
        title: 'Seguridad',
        subTitle: 'Adapta los permisos según tus preferencias.',
        icon: 'shield',
        fill: 'white',
        navigation: '/shield',
      },
    ],
  },
  {
    id: 2,
    isNotshowInDesktop: true,
    title: 'Auditoría odontológica',
    titlesContainMenu: null,
    titleDesktop: null,
    navLink: null,
    subTitle:
      'Auditá las liquidaciones de prestadores según tus reglas predefinidas.',
    details: [
      {
        title: 'Automatización de procesos',
        subTitle: 'Optimiza el trabajo de tus auditores.',
        icon: 'monitorDot',
        fill: '#0000',
        navigation: '/auditory#hero',
      },
      {
        title: 'Beneficios del software',
        subTitle: 'Explora los beneficios para el auditor.',
        icon: 'arward',
        fill: '#0000',
        navigation: '/auditory#auditoryFeatures',
      },
      {
        title: 'Capacitaciones',
        subTitle: 'Contáctanos para recibir acompañamiento.',
        icon: 'heartHandshake',
        fill: '#0000',
        navigation: '/auditory#experienceImprove',
      },
    ],
  },
  {
    id: 3,
    title: 'Nosotros',
    titleDesktop: 'Nosotros',
    subTitle: null,
    isNotshowInDesktop: false,
    titlesContainMenu: null,
    navLink: null,
    details: [
      {
        title: 'Historia',
        subTitle:
          'Somos la compañía tecnológica líder en gestión odontológica.',
        logo: 'icon-bilog.png',
        navigation: '/about',
      },
      {
        title: 'Testimonios',
        subTitle:
          'Conoce las historias de clientes y sus experiencias con Bilog.',
        icon: 'heart',
        fill: 'white',
        navigation: '/testimonials',
      },
    ],
  },
  {
    id: 4,
    title: 'Recursos',
    titleDesktop: 'Recursos',
    subTitle: null,
    isNotshowInDesktop: false,
    titlesContainMenu: null,
    navLink: null,
    details: [
      {
        title: 'Preguntas frecuentes',
        subTitle: 'Todas tus dudas resueltas en un solo lugar.',
        icon: 'chat',
        fill: 'white',
        navigation: '/faqs',
      },
    ],
  },
  {
    id: 5,
    titleDesktop: 'Tarifas',
    navLink: 'https://payments.bilog.com.ar',
    subTitle: null,
    isNotshowInDesktop: false,
    titlesContainMenu: null,
    title: 'Tarifas',
    details: null,
  },
];
