export const expertiseOptions = [
  { label: 'Odontólogo/a', value: 'odontólogo/a' },
  { label: 'Laboratorista', value: 'laboratorista' },
  { label: 'Secretario/a', value: 'secretario/a' },
  { label: 'Administrador/a', value: 'administrador/a' },
  { label: 'Otro', value: 'otro' },
];

export const preferenceOptions = [
  { label: 'Mail', value: 'mail' },
  { label: 'Llamado telefónico', value: 'llamado telefónico' },
  { label: 'Whatsapp', value: 'whatsapp' },
];
export const talkToOptions = [
  { label: 'Soporte', value: 'soporte' },
  { label: 'Ventas', value: 'ventas' },
  { label: 'Capacitaciones', value: 'capacitaciones' },
  { label: 'Otro', value: 'otro' },
];
