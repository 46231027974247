import React from 'react';
import {
  ProductHighlight,
  Remembers,
  Stories,
  Odontogram,
  OtherSections,
  PatientFeatures,
  FeaturedItems,
  Testimonial,
  Recipes,
} from '#components/organisms';
import { Layout } from '#hocs/layout';

const PatientsView = () => {
  const section = 'patients';

  return (
    <Layout section={section}>
      <ProductHighlight section={section} />
      <FeaturedItems section={section} />
      <Odontogram />
      <Remembers
        header="Presupuestos y planes de tratamiento"
        title="Presupuestos dentales personalizados"
        section="patients_budgets"
      />
      <PatientFeatures />
      <Recipes />
      <Remembers
        header="Laboratorio"
        title="Laboratorios de confianza, resultados excepcionales"
        section="patients_laboratories"
      />
      <OtherSections />
      <Stories
        title="Historias como la tuya"
        description="Miles de profesionales como vos eligen y seguen eligiendo a Bilog, conoce sus experiencias."
      />
      <div className="lg:hidden">
        <Testimonial />
      </div>
    </Layout>
  );
};
export default PatientsView;
