import React from 'react';
import classNames from 'classnames';
import {
  Badge,
  Carousel,
  CarouselContent,
  CarouselItem,
  Copy,
  Image,
  Title,
} from '#components/atoms';
import { features } from '#mocks/patients';
import { useDeviceDetector } from '../../hooks';

export const PatientFeatures = () => {
  const { MobileView, TabletView, DesktopView } = useDeviceDetector();

  return (
    <div className="m-5 lg:mx-20">
      <Copy className="!text-blue-400 mb-1 lg:text-center lg:!text-xl !font-bold 2xl:!text-xl">
        Historia clínica, pagos, turnos y galería
      </Copy>
      <Title className="text-3xl mb-8 lg:mb-8 lg:text-5xl lg:text-center 2xl:!text-6xl">
        Pacientes, con todo lo que necesitas en un solo lugar
      </Title>
      <MobileView>
        <Carousel autoPlay className="pt-2">
          <CarouselContent>
            {features.map((item, index) => (
              <CarouselItem
                key={index}
                className="!basis-5/6 md:!basis-2/3 lg:!basis-[24%] max-w-lg"
              >
                <Items
                  title={item.title}
                  description={item.description}
                  img={item.img}
                  badge={item.badge}
                  classnames={item.classnames}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </MobileView>
      <TabletView>
        <Carousel autoPlay className="pt-2">
          <CarouselContent>
            {features.map((item, index) => (
              <CarouselItem
                key={index}
                className="!basis-5/6 md:!basis-2/3 lg:!basis-[24%] max-w-lg"
              >
                <Items
                  title={item.title}
                  description={item.description}
                  img={item.img}
                  badge={item.badge}
                  classnames={item.classnames}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </TabletView>
      <DesktopView>
        <div className="grid grid-cols-2 gap-6">
          {features.map((item, index) => (
            <Items
              key={index}
              title={item.title}
              description={item.description}
              img={item.img}
              badge={item.badge}
              classnames={item.classnames}
            />
          ))}
        </div>
      </DesktopView>
    </div>
  );
};

const Items = ({
  img,
  title,
  description,
  badge,
  classnames,
}: {
  img: string;
  title: string;
  description: string;
  badge: string;
  classnames: string;
}) => {
  return (
    <div className="p-3">
      <Image
        src={require(`#assets/products/patient-${img}-mobile.png`)}
        alt="device-frame"
        className="w-full object-contain mb-4"
      />
      <Badge
        className={classNames('mb-4 lg:!text-base 2xl:!text-2xl', classnames)}
      >
        {badge}
      </Badge>
      <Title className="text-base lg:text-xl 2xl:text-2xl">{title}</Title>
      <Copy className="mb-16 lg:!text-base 2xl:!text-xl">{description}</Copy>
    </div>
  );
};
