import React from 'react';
import classNames from 'classnames';
import { TabsTrigger } from '#components/atoms';
import { useDeviceDetector } from '../../hooks';

export const tabsFilter = (
  tabs: { [key: string]: any },
  customClass?: string,
) => {
  return tabs.map((tab: { [key: string]: any }, i: number) => {
    const { deviceType } = useDeviceDetector();
    const Icon = tab.icon;
    return (
      <TabsTrigger
        key={i}
        className={classNames(
          customClass,
          deviceType !== 'desktop' && tab.classnames,
        )}
        value={tab.value}
      >
        <Icon className="size-6" />
        {tab.text}
      </TabsTrigger>
    );
  });
};
