import React from 'react';
import classNames from 'classnames';
import { Card, Copy, Title } from '#components/atoms';
import { remembers } from '#mocks/schedule';

export const Remembers = ({
  section,
  title,
  header,
  classnames = '!text-blue-400',
}: {
  section: string;
  title?: string;
  header?: string;
  classnames?: string;
}) => {
  return (
    <Card className="flex flex-col !py-10 lg:my-28 lg:px-24">
      {header && (
        <Copy
          className={classNames(
            'mb-1 text-base lg:text-xl md:text-base lg:text-center font-bold',
            classnames,
          )}
        >
          {header}
        </Copy>
      )}
      {title && (
        <Title className="text-3xl mb-8 lg:mb-10 lg:!text-5xl lg:text-center 2xl:!text-6xl">
          {title}
        </Title>
      )}
      <div className="lg:grid lg:grid-cols-3">
        {remembers[section].map((item: any, index: number) => (
          <div
            key={index}
            className="bg-white border rounded-sm py-10 px-5 flex flex-col items-center justify-center shadow-md"
          >
            <item.icon className="text-blue-400 mb-5" />
            <Title className="!text-base !font-bold mb-2 2xl:!text-xl">
              {item.title}
            </Title>
            <Copy className="!font-regular !text-base mb-2.5 text-center">
              {item.description}
            </Copy>
          </div>
        ))}
      </div>
    </Card>
  );
};