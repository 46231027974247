import React from 'react';
import logo from '#assets/icons/logo-bilog.svg';
import { Image } from '#components/atoms';
import { LogoT } from '#interfaces/components';

export const Logo: React.FC<LogoT> = ({
  height,
  width,
  classname,
  onclick,
}) => {
  return (
    <Image
      className={classname}
      src={logo}
      alt="Logotipo Bilog"
      width={width}
      height={height}
      onclick={onclick}
    />
  );
};
