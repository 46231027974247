import React from 'react';
import classNames from 'classnames';
import { BadgeT } from '#interfaces/components';

export const Badge: React.FC<BadgeT> = ({ children, className }) => {
  const badgeCss = classNames(
    'inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-bold text-blue-600',
    className,
  );

  return <span className={badgeCss}>{children}</span>;
};
