export const features: {
  title: string;
  description: string;
  platforms: {
    title: string;
    icon: string;
    classname: string;
    fill: string;
  }[];
}[] = [
  {
    title: 'Liquidación de profesionales',
    description:
      'Permite ver prestaciones realizadas, los tipos de pago que pueden liquidarse y los totales de laboratorio.',
    platforms: [
      {
        title: 'Escritorio',
        icon: 'desktop',
        classname: '!text-green-600 !bg-green-50',
        fill: '#ffff',
      },
    ],
  },
  {
    title: 'Liquidación de obras sociales / Seguros médicos',
    description:
      'Ofrece opciones de filtrado por obras sociales, grupos, profesionales y periodos.',
    platforms: [
      {
        title: 'Móvil',
        icon: 'mobile',
        classname: '!text-amber-600 !bg-amber-50',
        fill: '#ffff',
      },
      {
        title: 'Web',
        icon: 'world',
        classname: '!text-violet-600 !bg-violet-50',
        fill: '#ffff',
      },
      {
        title: 'Escritorio',
        icon: 'desktop',
        classname: '!text-green-600 !bg-green-50',
        fill: '#ffff',
      },
    ],
  },
  {
    title: 'Informes de pacientes',
    description:
      'Acceso a lista de pacientes ingresados. Información detallada sobre las prestaciones realizadas, cumpleaños y más.',
    platforms: [
      {
        title: 'Web',
        icon: 'world',
        classname: '!text-violet-600 !bg-violet-50',
        fill: '#ffff',
      },
    ],
  },
  {
    title: 'Liquidación de laboratorios y auditorias',
    description:
      'Visualiza los pendientes de entrega, los montos totales del laboratorio, incluyendo pedidos realizados, recibidos y pagados.',
    platforms: [
      {
        title: 'Web',
        icon: 'world',
        classname: '!text-violet-600 !bg-violet-50',
        fill: '#ffff',
      },
    ],
  },
  {
    title: 'Liquidación de auditor',
    description:
      'Ofrece opciones de filtrado por obras sociales, grupos, profesionales y periodos.',
    platforms: [
      {
        title: 'Móvil',
        icon: 'mobile',
        classname: '!text-amber-600 !bg-amber-50',
        fill: '#ffff',
      },
      {
        title: 'Web',
        icon: 'world',
        classname: '!text-violet-600 !bg-violet-50',
        fill: '#ffff',
      },
      {
        title: 'Escritorio',
        icon: 'desktop',
        classname: '!text-green-600 !bg-green-50',
        fill: '#ffff',
      },
    ],
  },
];
