export const stories = [
  {
    id: 'alvarado',
    title:
      'Elección, apuesta y cambio son los 3 factores clave de la clínica Alvarado.',
    description:
      'El Dr. Antonio Alvarado, Odontólogo especialista en Rehabilitación Oral e Implantes y Director de Clínica Odonto...',
    img: 'alvarado',
  },

  {
    id: 'orono',
    title: 'Optimización del tiempo, el método de las clínicas Oroño Dent.',
    description:
      'Todo comenzó hace más de diez años con la necesidad de una herramienta para potenciar y organizar el día a día, un poco mas...',
    img: 'orono',
  },
  {
    id: 'calcagno',
    title:
      'Trayectoria y buena comunicación, la fórmula del éxito de Espacio Dental.',
    description:
      'Hablamos con una de nuestras más fieles usuarias. Les queremos contar el caso de Liliana Calcagno, odontóloga co...',
    img: 'calcagno',
  },
  {
    id: 'marion',
    title:
      'La estrategia de la Dra. Laura Marion para mejorar el control de su clínica.',
    description:
      'Optimización de tiempos y organización en el desarrollo de su profesión fue lo que motivó, hace 12 años, a la Dra. Ma...',
    img: 'marion',
  },
];
