import React from 'react';
import { Layout } from '#hocs/layout';
import { Image } from '#components/atoms';

const WorkingView = () => {
  return (
    <Layout section="working">
      <div className="px-5 pb-14 justify-center items-center flex">
        <Image
          className="w-auto md:w-6/12 lg:-mt-32"
          src={require('#assets/working.png')}
        />
      </div>
    </Layout>
  );
};
export default WorkingView;
