import React from 'react';
import classNames from 'classnames';
import { Card, Title } from '#components/atoms';
import { DATA_BANNERS } from '#mocks/banners.tsx';
import { useDeviceDetector } from '../../hooks';

export const Banners = ({ section }: { section: string }) => {
  const { deviceType } = useDeviceDetector();
  const { title, img, description, buttons, classname } = DATA_BANNERS[section];

  return img ? (
    <Card
      className={classNames(
        'relative flex flex-col md:grid md:grid-cols-12 !p-0 border !shadow-md md:mb-20 lg:!h-[298px] md:mx-24',
        classname,
      )}
    >
      <div
        className="absolute inset-0 bg-center md:w-auto bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url(${require(`#assets/${img}-${
            deviceType === 'tablet' ? 'mobile' : deviceType
          }.webp`)})`,
        }}
      />
      <div className="flex flex-col items-center justify-center m-5 mb-8 pt-64 z-10 md:pt-0 md:col-span-6 md:items-start md:m-11">
        <Title className="text-center lg:text-start text-2xl lg:mb-8">
          {title}
        </Title>
        {description}
        {buttons}
      </div>
    </Card>
  ) : (
    <Card className={classNames('relative flex flex-col', classname)}>
      <div className="flex flex-col items-center justify-center gap-8 m-5 mb-8 z-10 ">
        <Title className="text-center text-3xl font-semibold lg:text-5xl ">
          {title}
        </Title>
        {description}
        {buttons}
      </div>
    </Card>
  );
};
