import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { Icons } from '#components/atoms/icons';

const Accordion: React.FC<any> = AccordionPrimitive.Root;
type AccordionT = {
  className?: string;
  children?: React.ReactNode;
  value?: string;
  id?: string;
};

const AccordionItem = React.forwardRef<HTMLDivElement, AccordionT>(
  ({ className, value, ...props }, ref) => (
    <AccordionPrimitive.Item
      ref={ref}
      className={classNames('border-b rounded-md p-4 mb-6', className)}
      value={value || ''}
      {...props}
    />
  ),
);
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<HTMLButtonElement, AccordionT>(
  ({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Header className="flex">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={classNames(
          'flex flex-1 items-center text-left justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180',
          className,
        )}
        {...props}
      >
        {children}
        <Icons
          className="h-4 w-4 shrink-0 transition-transform duration-200"
          fill="gray"
          type="arrowDown"
        />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  ),
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<HTMLDivElement, AccordionT>(
  ({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Content
      ref={ref}
      className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
      {...props}
    >
      <div className={classNames('pb-4 pt-0', className)}>{children}</div>
    </AccordionPrimitive.Content>
  ),
);

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
