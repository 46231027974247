import React from 'react';
import * as Ariakit from '@ariakit/react';
import { flushSync } from 'react-dom';
import classNames from 'classnames';
import { Icons, Copy, Title } from '#components/atoms';

type MenuContextProps = {
  getWrapper: () => HTMLElement | null;
  getMenu: () => HTMLElement | null;
  getOffsetRight: () => number;
};

const MenuContext = React.createContext<MenuContextProps | null>(null);

export type MenuProps = React.HTMLAttributes<HTMLDivElement> & {
  label?: React.ReactNode;
  disabled?: boolean;
  subLabel?: string;
  classname?: string;
  navLink?: string;
  index?: number;
};

export const Menu = React.forwardRef<HTMLDivElement, MenuProps>(
  ({ label, children, subLabel, navLink, classname, ...props }, ref) => {
    const parent = React.useContext(MenuContext);
    const isSubmenu = !!parent;
    const [blockscroll, setBlockScroll] = React.useState(false);

    const menu = Ariakit.useMenuStore({
      placement: isSubmenu ? 'right-start' : 'bottom-start',
      animated: isSubmenu ? 500 : false,
    });

    const open = menu.useState('open');
    const autoFocusOnShow = menu.useState('autoFocusOnShow');

    React.useLayoutEffect(() => {
      if (!autoFocusOnShow) {
        menu.setAutoFocusOnShow(true);
      }
    }, [autoFocusOnShow, menu]);

    React.useLayoutEffect(() => {
      if (open) {
        setBlockScroll(true);
        menu.stopAnimation();
      }
      if (!open) {
        setBlockScroll(false);
      }
    }, [open, menu]);

    React.useMemo(() => {
      blockscroll
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'auto');
    }, [blockscroll]);

    const contextValue = React.useMemo<MenuContextProps>(
      () => ({
        getWrapper: () =>
          parent?.getWrapper() || menu.getState().popoverElement,
        getMenu: () => menu.getState().baseElement,
        getOffsetRight: () =>
          (parent?.getOffsetRight() ?? 0) +
          (menu.getState().baseElement?.offsetWidth ?? 0),
      }),
      [menu, parent],
    );

    React.useEffect(() => {
      if (!parent) return;
      const parentWrapper = parent.getWrapper();
      if (!parentWrapper) return;
      let timeout = 0;
      const onScroll = () => {
        clearTimeout(timeout);
        timeout = window.setTimeout(() => {
          const scrollLeft = Math.abs(parentWrapper.scrollLeft);
          const wrapperOffset = scrollLeft + parentWrapper.clientWidth;
          if (wrapperOffset <= parent.getOffsetRight()) {
            flushSync(menu.hide);
            menu.stopAnimation();
          }
        }, 100);
      };
      parentWrapper.addEventListener('scroll', onScroll);

      return () => parentWrapper.removeEventListener('scroll', onScroll);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parent, menu.hide, menu.stopAnimation]);

    const renderMenuButton = (menuButtonProps: any) =>
      navLink ? (
        <Copy className="flex justify-between w-full !text-base !font-bold text-slate-800">
          <a href={navLink} target="blank">
            {label}
          </a>
        </Copy>
      ) : (
        <Ariakit.MenuButton
          store={menu}
          showOnHover={false}
          className="button"
          render={<button />}
          {...menuButtonProps}
        >
          {label ? (
            <div className="flex justify-between w-80">
              <div className="flex flex-col">
                <Copy className="!text-base !font-bold text-slate-800">
                  {label}
                </Copy>
                <Copy>{subLabel}</Copy>
              </div>
              <Icons type="arrowRight" />
            </div>
          ) : (
            <button className="border-muted size-6 w-full">
              <div
                className={classNames(
                  'w-6 h-1 bg-gray-700 rounded-md transition-transform duration-300',
                  {
                    'transform rotate-45 translate-y-2.5': open,
                  },
                )}
              ></div>
              <div
                className={classNames(
                  'w-6 h-1 bg-gray-700 my-1 rounded-md transition-opacity duration-300',
                  {
                    'opacity-0': open,
                  },
                )}
              ></div>
              <div
                className={classNames(
                  'w-6 h-1 bg-gray-700 rounded-md transition-transform duration-300',
                  {
                    'transform -rotate-45 -translate-y-1.5': open,
                  },
                )}
              ></div>
            </button>
          )}
        </Ariakit.MenuButton>
      );

    const wrapperProps = {
      className: classNames({
        'overscroll-auto z-50 bg-white overflow-x-hidden scroll-smooth w-80 space-x-4 shadow-md rounded-md':
          !isSubmenu,
      }),
    };

    const autoFocus = (element: any) => {
      if (!isSubmenu) return true;
      element?.focus({ preventScroll: true });
      element?.scrollIntoView({ block: 'nearest', inline: 'end' });
      return false;
    };

    return (
      <>
        {isSubmenu ? (
          <Ariakit.MenuItem
            ref={ref}
            focusOnHover={false}
            className="flex overflow-hidden scroll-margin-[0.5rem] justify-between bg-aliceblue gap-0.5 border-radius-0.25 text-left outline-none mb-4"
            {...props}
          >
            {renderMenuButton}
          </Ariakit.MenuItem>
        ) : (
          renderMenuButton({ ref, ...props })
        )}

        <Ariakit.Menu
          store={menu}
          className={classNames(
            'z-50 flex flex-col bg-white w-96 p-5 rounded-md overflow-y-auto overflow-x-hidden h-[500px] justify-between',
            {
              '!justify-start': Array.isArray(children) && children.length <= 3,
            },
          )}
          unmountOnHide
          portal={isSubmenu}
          portalElement={parent?.getWrapper}
          wrapperProps={wrapperProps}
          autoFocusOnShow={autoFocus}
          autoFocusOnHide={autoFocus}
          overflowPadding={isSubmenu ? 0 : 8}
          gutter={isSubmenu ? 0 : 8}
          flip={!isSubmenu}
          getAnchorRect={(anchor: any) => {
            return (
              parent?.getMenu()?.getBoundingClientRect() ||
              anchor?.getBoundingClientRect() ||
              null
            );
          }}
        >
          <MenuContext.Provider value={contextValue}>
            {isSubmenu && (
              <div>
                <Title className="flex gap-7">
                  <Ariakit.MenuItem
                    hideOnClick={false}
                    focusOnHover={false}
                    onClick={menu.hide}
                    className={classname}
                    aria-label="Back to parent menu"
                    render={<button />}
                  >
                    <Ariakit.MenuButtonArrow placement="left" />
                  </Ariakit.MenuItem>
                  <Ariakit.MenuHeading className="heading">
                    {label}
                  </Ariakit.MenuHeading>
                </Title>
                <MenuSeparator />
              </div>
            )}
            {children}
          </MenuContext.Provider>
        </Ariakit.Menu>
      </>
    );
  },
);

export type MenuItemProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  label?: React.ReactNode;
  disabled?: boolean;
  classname?: string;
  index?: number;
};

export const MenuItem = React.forwardRef<HTMLButtonElement, MenuItemProps>(
  ({ label, classname, ...props }, ref) => {
    return (
      <Ariakit.MenuItem
        className={classname}
        focusOnHover={false}
        render={<button ref={ref} {...props} />}
      >
        {label}
      </Ariakit.MenuItem>
    );
  },
);

export type MenuSeparatorProps = React.HTMLAttributes<HTMLHRElement>;

export const MenuSeparator = React.forwardRef<
  HTMLHRElement,
  MenuSeparatorProps
>(() => {
  return (
    <div className="mt-2 mb-2 h-0 w-full border-t border-dotted border-gray-300"></div>
  );
});

export type MenuGroupProps = React.HTMLAttributes<HTMLDivElement> & {
  label?: string;
};

export const MenuGroup = React.forwardRef<HTMLDivElement, MenuGroupProps>(
  ({ label, ...props }, ref) => {
    return (
      <Ariakit.MenuGroup ref={ref} {...props}>
        {label && <Ariakit.MenuGroupLabel>{label}</Ariakit.MenuGroupLabel>}
        {props.children}
      </Ariakit.MenuGroup>
    );
  },
);
