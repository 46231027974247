import React from 'react';
import { Product } from './sections/product';
import { DATA_PRODUCTS } from '#mocks/products';

export const ProductHighlight: React.FC<{ section: string }> = ({
  section,
}) => {
  return DATA_PRODUCTS[section]?.map((d: any, cardIndex: number) => (
    <Product key={cardIndex} data={d} />
  ));
};
