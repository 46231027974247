import React from 'react';
import { Title } from '#components/atoms';
import { Faq } from '#components/molecules';
import { dataFaqs } from '#mocks/faqs';

export const Faqs: React.FC = () => {
  return (
    <div className="lg:flex lg:flex-col lg:items-center overflow-hidden mx-5 lg:mx-24 py-12">
      <Title className="m-4 text-3xl lg:!text-5xl lg:col-span-full lg:col-start-5 2xl:!text-6xl">
        Preguntas frecuentes
      </Title>
      <Faq data={dataFaqs} />
    </div>
  );
};
