import React from 'react';
import classNames from 'classnames';
import { Badge, Copy, Image, Title } from '#components/atoms';

export const Role = ({
  title,
  header,
  data,
  classnames = 'text-blue-400',
}: {
  title: string;
  header: string;
  data: {
    badge: string;
    description: string;
    className: string;
    img: string;
  }[];
  classnames?: string;
}) => {
  return (
    <div className="lg:flex lg:flex-col lg:items-center overflow-hidden mx-5 lg:mx-24 py-12">
      <Copy
        className={classNames(
          '!text-base !font-bold mb-2 md:mb-5 md:!text-start lg:!text-2xl 2xl:!text-2xl',
          classnames
        )}
      >
        {header}
      </Copy>
      <Title className="font-normal text-3xl mb-8 lg:mb-12 md:!text-start md:font-semibold lg:font-normal lg:!text-5xl 2xl:!text-6xl 2xl:mb-12">
        {title}
      </Title>
      <div className="md:grid grid-cols-12 md:gap-4 lg:flex">
        {data?.map((item, index) => (
          <Item
            badge={item.badge}
            classname={item.className}
            description={item.description}
            img={item.img}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

const Item = ({
  badge,
  classname,
  description,
  img,
}: {
  badge: string;
  classname: string;
  description: string;
  img: string;
}) => {
  return (
    <div className="p-5 rounded-sm shadow-md mb-5 lg:mb-0 w-full flex md:col-span-6 md:mb-0">
      <div>
        <Badge className={classname}>{badge}</Badge>
        <Copy className="mb-2">{description}</Copy>
      </div>
      <Image
        src={require(`#assets/products/${img}`)}
        width={400}
        height={300}
        alt="product-image"
        className="w-20 h-24"
      />
    </div>
  );
};
