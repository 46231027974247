import React from 'react';
import {
  WorkWhitUs,
  Testimonial,
  Banners,
  Stories,
} from '#components/organisms';
import { Layout } from '#hocs/layout';

const TestimonalsView = () => {
  const section = 'testimonials';
  return (
    <Layout section={section}>
      <WorkWhitUs />
      <Testimonial />
      <Banners section={section} />
      <Stories
        title="Otros casos de éxito"
        description="Si quieres conocer mas casos de éxito sigue navegando por nuestras historias."
      />
    </Layout>
  );
};

export default TestimonalsView;
