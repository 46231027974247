import React from 'react';
import {
  Card,
  Carousel,
  CarouselContent,
  CarouselItem,
  Copy,
  Image,
} from '#components/atoms';

export const WorkWhitUs = () => {
  return (
    <Card className="flex flex-col justify-center lg:flex lg:flex-col lg:items-center lg:text-center !py-10">
      <Copy className="!text-xl md:hidden lg:!text-2xl lg:block 2xl:!text-3xl">
        Con la confianza de las clínicas líderes de Argentina y Latam.
      </Copy>
      <Carousel className="flex flex-col w-full" isVisibleDots={false} autoPlay>
        <CarouselContent>
          {['dental-centro', 'mb', 'marion', 'garcia', 'apo'].map(
            (item, index) => (
              <CarouselItem
                key={index}
                className="md:!basis-1/3 flex items-center"
              >
                <Card className="!p-6 flex justify-center w-full">
                  <Image
                    src={require(`#assets/clinics/${item}.png`)}
                    alt="clinics"
                    width={100}
                    height={100}
                    className="w-52 max-h-32 object-contain"
                  />
                </Card>
              </CarouselItem>
            ),
          )}
        </CarouselContent>
      </Carousel>
    </Card>
  );
};
