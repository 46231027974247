import React from 'react';
import { Copy, Title } from '#components/atoms';
import { Faq } from '#components/molecules';
import { dataFaqs } from '#mocks/faqs';

export const Doubts = ({ selectFilter }: { selectFilter: string }) => {
  return (
    <div className="mx-5 lg:mx-24 md:pt-2 my-10 md:pb-7 lg:py-8 md:flex md:flex-col overflow-hidden">
      <Title className="text-3xl mb-4 lg:mb-1 lg:text-center lg:!text-5xl 2xl:!text-6xl">
        Aclaramos tus dudas
      </Title>
      <Copy className="!text-base mb-4 md:mb-16 lg:text-center md:!text-xl lg:mb-10">
        Si quieres saber aun más de esta funcionalidad, te dejamos respuestas a
        las preguntas habituales.
      </Copy>
      <Faq data={dataFaqs} selectFilter={selectFilter} />
    </div>
  );
};
