import React from 'react';
import classNames from 'classnames';
import { Copy, Image, Title } from '#components/atoms';
import { featuredItems } from '#mocks/featured-items';
import { useDeviceDetector } from '../../../hooks';

export const FeaturedItems = ({
  section,
  classnames = '!text-blue-400',
  background,
}: {
  section: string;
  classnames?: string;
  background?: string;
}) => {
  const { deviceType } = useDeviceDetector();
  const { items, header, title, description, img, styles } =
    featuredItems[section];
  const imgStyles = classNames(
    'w-full lg:w-[65%] max-h-[600px] object-fill top-44 lg:translate-x-[57%] lg:block lg:absolute lg:overflow-visible',
    {
      'hidden lg:w-[68%] !top-40': !['about'].includes(section),
    },
  );

  return img ? (
    <div className="mx-5 md:pt-2 md:pb-7 lg:py-8 md:flex md:flex-col lg:ml-24 lg:m-0 relative overflow-hidden">
      <Copy
        className={classNames(
          '!text-base lg:!text-xl !font-bold mb-2 md:mb-5 md:text-center 2xl:!text-2xl lg:mr-24',
          classnames,
        )}
      >
        {header}
      </Copy>
      <Title className="text-3xl mb-8 lg:mb-12 md:text-center lg:!text-5xl 2xl:!text-6xl 2xl:mb-12 lg:mr-24">
        {title}
      </Title>
      <Copy className="!text-base mb-12 text-center mx-32">{description}</Copy>
      <div className="md:max-w-full lg:flex lg:gap-x-8 lg:max-w-96 2xl:max-w-lg">
        <div>
          {items?.map((item, index) => (
            <Item
              key={index}
              title={item.title}
              description={item.description}
              styles={styles}
            />
          ))}
        </div>
        <Image
          src={require(`#assets/products/${img}-${
            deviceType !== 'desktop' ? 'mobile' : deviceType
          }.png`)}
          width={400}
          height={300}
          alt="product-image"
          className={imgStyles}
        />
      </div>
    </div>
  ) : (
    <div className={classNames(background)}>
      <div
        className={classNames(
          'mx-5 md:pt-2 md:pb-7 lg:py-8 md:flex md:flex-col lg:m-0 lg:px-24 overflow-hidden relative',
        )}
      >
        <Copy
          className={classNames(
            '!text-base lg:!text-xl !font-bold mb-1 md:mb-2 text-start lg:text-center 2xl:!text-2xl',
            classnames,
          )}
        >
          {header}
        </Copy>
        <Title className="text-3xl font-normal mb-6 md:mb-5 text-start lg:text-center lg:!text-5xl 2xl:!text-6xl">
          {title}
        </Title>
        <Copy className="!text-base mb-8 text-start hidden md:block md:self-stretch lg:text-center lg:w-max-[49.75rem]">
          {description}
        </Copy>
        <div className="md:max-w-full lg:flex lg:flex-row lg:grid-rows-3 lg:full 2xl:w-full">
          <div className="lg:grid lg:grid-cols-2 lg:gap-4">
            {items?.map((item, index) => (
              <Item
                key={index}
                title={item.title}
                description={item.description}
                styles={styles}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Item = ({
  title,
  description,
  styles,
}: {
  title: string;
  description: string;
  styles?: string;
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col lg:h-auto mb-4 lg:mb-10 shadow rounded p-5 bg-white',
        styles,
      )}
    >
      <Copy className="!text-base !font-bold 2xl:!text-xl">{title}</Copy>
      <Copy className="!text-base">{description}</Copy>
    </div>
  );
};
