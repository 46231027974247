import React from 'react';
import { ExtendTestimonial } from '#components/organisms';
import { Layout } from '#hocs/layout';

const ExtendTestimonials = () => {
  return (
    <Layout>
      <ExtendTestimonial />
    </Layout>
  );
};

export default ExtendTestimonials;
