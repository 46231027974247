import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ReactTagManager } from 'react-gtm-ts';
import {
  AboutView,
  ContactView,
  ExtendTestimonials,
  HomeView,
  ScheduleView,
  TestimonalsView,
  ReportsView,
  PatientsView,
  FaqsView,
  TermsView,
  WorkingView,
  ShieldView,
  StatisticsView,
  AuditoryView,
} from './pages';
import { ScrollToTop } from '#hocs/scroll-top';

function App() {
  React.useEffect(() => {
    ReactTagManager.init({
      code: 'GTM-PZ395V42',
      debug: false,
      performance: false,
    });
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="/schedule" element={<ScheduleView />} />
        <Route path="/testimonials" element={<TestimonalsView />} />
        <Route path="/reports" element={<ReportsView />} />
        <Route path="/patients" element={<PatientsView />} />
        <Route path="/testimonials/:id" element={<ExtendTestimonials />} />
        <Route path="/faqs" element={<FaqsView />} />
        <Route path="/contact" element={<ContactView />} />
        <Route path="/terms_and_conditions" element={<TermsView />} />
        <Route path="/statistics" element={<StatisticsView />} />
        <Route path="/about" element={<AboutView />} />
        <Route path="/shield" element={<ShieldView />} />
        <Route path="/auditory" element={<AuditoryView />} />
        <Route path="/*" element={<WorkingView />} />
      </Routes>
    </Router>
  );
}

export default App;
