import React from 'react';
import {
  Card,
  Carousel,
  CarouselContent,
  CarouselItem,
  Copy,
  Image,
  Timeline,
  Title,
} from '#components/atoms';
import { history } from '#mocks/about-us';

export const OurHistory: React.FC = () => {
  return (
    <Card className="overflow-hidden flex-col lg:!justify-start !py-10 lg:items-center">
      <Copy className="!text-base lg:!text-xl !font-bold !text-blue-400 mb-2 md:mb-5 lg:text-center 2xl:!text-2xl">
        Nuestra historia
      </Copy>
      <Title className="text-3xl mb-8 lg:mb-12 lg:text-center lg:!text-5xl 2xl:!text-6xl 2xl:mb-12">
        Así comenzó todo
      </Title>
      <Carousel className="md:hidden">
        <CarouselContent>
          {history.map((item, index) => (
            <CarouselItem
              key={index}
              className="!basis-5/6 md:!basis-2/3 lg:!basis-[24%] max-w-lg"
            >
              <Item
                title={item.title}
                icon={item.icon}
                description={item.description}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
      <Timeline data={history} />
    </Card>
  );
};

const Item = ({
  title,
  icon,
  description,
}: {
  title: string;
  icon: string;
  description: string;
}) => {
  return (
    <>
      <Card className="w-full relative !p-0">
        <div className="w-full h-8 rounded-t-sm bg-blue-200" />
        <Image
          src={require(`#assets/icons/${icon}`)}
          width={400}
          height={300}
          alt="product-image"
          className="absolute size-14 left-6 top-3 2xl:size-16"
        />
      </Card>
      <div className="mt-5 p-6">
        <Copy className="!text-base text-slate-900 mb-4 uppercase 2xl:!text-2xl">
          {title}
        </Copy>
        <Copy className="2xl:!text-xl">{description}</Copy>
      </div>
    </>
  );
};
