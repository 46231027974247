import React from 'react';
import { LuLoader2 as Loader2 } from 'react-icons/lu';
import { Button } from '#components/atoms';

export function ButtonLoading({ className }: { className?: string }) {
  return (
    <Button disabled className={className}>
      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
      Cargando...
    </Button>
  );
}
