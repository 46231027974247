import React from 'react';
import { HiCheckCircle } from 'react-icons/hi2';
import { Copy, Image, Title, Badge, Icons } from '#components/atoms';
import { recipes } from '#mocks/patients';

export const Recipes = () => {
  const { title, header, description, check_list } = recipes || {};
  const platforms = [
    {
      title: 'Móvil',
      icon: 'mobile',
      classname: '!text-amber-600 !bg-amber-50',
      fill: '#ffff',
    },
    {
      title: 'Web',
      icon: 'world',
      classname: '!text-violet-600 !bg-violet-50',
      fill: '#ffff',
    },
  ];

  return (
    <div className="mx-5 mt-20 mb-10 lg:my-28 lg:mx-24">
      <Copy className="lg:!text-xl !font-bold !text-blue-400 mb-1 lg:text-center 2xl:!text-2xl">
        {header}
      </Copy>
      <Title className="text-3xl mb-4 lg:mb-8 lg:text-center lg:text-5xl 2xl:text-6xl">
        {title}
      </Title>
      <Copy className="!text-base mb-8 lg:hidden">{description}</Copy>
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-6">
        <Image
          src={require(`#assets/products/patient-recipes-mobile.png`)}
          alt="device-frame"
          className="w-full lg:order-last lg:col-span-5 object-contain"
        />
        <div className="hidden lg:block col-span-7">
          <Copy className="!text-base mb-6 mt-8 lg:!text-xl 2xl:!text-2xl">
            {description}
          </Copy>
          {platforms.map((platform: any, index) => (
            <Badge
              key={index}
              className={`p-5 !text-sm !py-2 ${platform.classname} mr-2.5
                !rounded-full w-fit`}
            >
              <Icons
                type={platform.icon}
                fill={platform.fill}
                className="mr-2 size-4"
              />
              <p>{platform.title}</p>
            </Badge>
          ))}
          {check_list?.map((item, index) => (
            <div className="grid grid-cols-12 mt-8 items-start" key={index}>
              <HiCheckCircle className="text-blue-500 col-span-1 w-7 h-7" />
              <Copy className="!text-2xl col-span-10">{item}</Copy>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
