import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Copy, Image, Title, Icons } from '#components/atoms';
import { TestimonialsHero } from '#components/organisms';

export const Hero: React.FC<{
  data: {
    title: string;
    description_desktop: string | React.ReactNode;
    description_mobile: string | React.ReactNode;
    image_mobile: string | null;
    image_desktop: string | null;
    extend_description: string | null;
    header: string | null;
    id: string;
  };
  fill?: string;
}> = ({ data, fill }) => {
  const {
    title,
    description_desktop,
    description_mobile,
    image_mobile,
    image_desktop,
    extend_description,
    header,
    id,
  } = data || {};
  const notAuditory = !['auditory'].includes(id);
  const navigator = useNavigate();
  const navigate = (url: string, target?: string) =>
    target ? window.open(url, target) : navigator(url);

  return (
    <div id="hero" className="relative overflow-hidden">
      <div className="max-w-screen-2xl mx-auto px-5 lg:pl-24 py-24 lg:!py-40 lg:!pb-40 !pb-8 gap-10 flex flex-col lg:grid lg:grid-cols-12 overflow-hidden lg:bg-center bg-no-repeat">
        <div
          className={classNames(
            ['testimonials'].includes(id) ? 'lg:col-span-5' : 'lg:col-span-6',
            ['faqs', 'contact', 'working', 'about'].includes(id) &&
              'md:!col-span-12 md:text-center',
          )}
        >
          <Copy
            className={classNames(
              '!text-base lg:!text-xl 2xl:!text-2xl !font-bold',
              notAuditory ? '!text-blue-400' : '!text-violet-400',
            )}
          >
            {header}
          </Copy>
          <Title className="z-10 !text-4xl !leading-[54px] lg:!text-[56px] 2xl:!text-6xl 2xl:!leading-[90px] lg:!leading-tight !text-slate-900">
            {title}
          </Title>
          <Copy className="z-10 mt-5 !text-xl md:hidden">
            {description_mobile}
          </Copy>
          <Copy
            className={classNames(
              'z-10 mt-3 hidden !text-xl md:block 2xl:!text-2xl',
              ['faqs', 'about'].includes(id) && 'md:mx-24',
            )}
          >
            {description_desktop}
          </Copy>
          {extend_description && (
            <Copy className="z-10 mt-3 hidden lg:block !text-xl">
              {extend_description}
            </Copy>
          )}
          {!['faqs', 'contact', 'working', 'about'].includes(id) && (
            <div className="z-10 flex items-center justify-center flex-col md:flex-row md:gap-5 lg:grid lg:grid-cols-12 lg:gap-5 gap-y-5 mt-8 md:mt-4 lg:mt-6">
              {notAuditory && (
                <Button
                  className="w-full h-12 lg:col-span-4 lg:h-10 2xl:!text-xl"
                  onClick={() =>
                    navigate('https://signup.bilog.com.ar/', '_blank')
                  }
                >
                  Regístrate gratis
                </Button>
              )}
              <Button
                className="w-full h-12 lg:col-span-4 lg:h-10 2xl:!text-xl"
                modifier={notAuditory ? 'white' : 'violet'}
                onClick={() =>
                  navigate('https://wapp.ly/l/20820ZeQv', '_blank')
                }
              >
                Contactar asesor
                <Icons type="whatsapp" fill={fill} className="size-5 ml-2" />
              </Button>
            </div>
          )}
        </div>
        {['testimonials'].includes(id) && <TestimonialsHero />}
      </div>
      {!['testimonials', 'faqs', 'contact', 'working', 'about'].includes(
        id,
      ) && (
        <>
          <Image
            src={require(`#assets/hero/${image_desktop || 'home-desktop.png'}`)}
            alt="device-frame"
            className="w-[68%] max-h-[455px] hidden object-contain top-20 lg:top-28 md:translate-x-[50%] lg:translate-x-[80%] 2xl:translate-x-[70%] lg:block absolute overflow-visible"
          />
          <Image
            src={require(`#assets/hero/${image_mobile || 'home-mobile.png'}`)}
            alt="device-frame"
            className="w-full lg:hidden object-contain"
          />
        </>
      )}
    </div>
  );
};
