import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Input, Label, Title } from '#components/atoms';
import { ButtonLoading, Combobox } from '#components/molecules';
import {
  expertiseOptions,
  talkToOptions,
  preferenceOptions,
} from '#mocks/contact';
import { sendEmail, getRegions } from '../../libs';
import { toast } from '../../hooks';

export const ContactForm = () => {
  const [state, setState] = React.useState<any>({
    newUser: {
      name: '',
      email: '',
      phone: '',
      expertise: '',
      preference: '',
      talk_to: '',
      message: '',
      country: '',
    },
    isVerified: false,
    sending: false,
  });
  const [regions, setRegions] = React.useState<any>([]);
  const { newUser, isVerified } = state;
  React.useMemo(() => {
    const fetchRegions = async () => {
      try {
        const {
          data: {
            response: { listaRegionesWapp },
          },
        } = await getRegions();
        const formatRegions = listaRegionesWapp?.map((r: any) => {
          return { label: r.name, value: r.name.toLowerCase() };
        });
        setRegions(formatRegions);
      } catch (error: any) {
        throw new Error(error);
      }
    };

    fetchRegions();
  }, []);

  const { email, name, phone, preference, talk_to, message } = newUser;
  const handleCaptchaVerification = (value: any) => {
    value &&
      setState((prevState: any) => ({
        ...prevState,
        isVerified: value,
      }));
  };
  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
    const requiredFields = !name || !email || !phone || !preference || !talk_to;

    if (requiredFields) {
      return toast({
        variant: 'destructive',
        title: 'Ups! Parece que hubo un error',
        description: 'Completa todos los campos requeridos',
      });
    }

    if (!isVerified) {
      return alert(
        'Necesitamos saber que sos humano, por favor completá el captcha.',
      );
    }

    try {
      setState((prevState: any) => ({
        ...prevState,
        sending: true,
      }));
      const res = await sendEmail({ body: newUser });
      if (res.status === 200) {
        toast({
          variant: 'successful',
          title: 'Mensaje enviado con éxito!',
          description:
            'Tu mensaje fue enviado con éxito. En breve te contactaremos!',
        });
        return setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      }
    } catch (err: any) {
      toast({
        variant: 'destructive',
        title: 'Ups! Parece que hubo un error',
        description: err.message,
      });
    } finally {
      setState((prevState: any) => ({
        ...prevState,
        sending: false,
      }));
    }
  };
  const handleClearForm = (e: any) => {
    e.preventDefault();
    setState({
      newUser: {
        name: '',
        email: '',
        phone: '',
        expertise: '',
        preference: '',
        talk_to: '',
        message: '',
      },
    });
  };
  const handleOnChange = (e: any) => {
    setState((prevState: any) => {
      return {
        ...prevState,
        newUser: { ...prevState.newUser, [e.target.name]: e.target.value },
      };
    });
  };

  return (
    <form
      onSubmit={handleOnSubmit}
      className="shadow-lg overflow-hidden mb-10 md:mb-24 md:mx-5 px-5 lg:px-10 py-10 lg:mx-24"
    >
      <Title className="text-3xl text-center mb-8 md:text-start lg:text-5xl">
        Formulario de contacto
      </Title>
      <div className="md:grid grid-cols-12 md:gap-x-4">
        <div className="col-span-full">
          <Label className="!font-bold !text-base text-slate-600">
            Nombre y apellido *
          </Label>
          <Input
            className="mb-5 mt-2"
            placeholder="Escribí tu nombre acá"
            name="name"
            value={name}
            onChange={handleOnChange}
          />
        </div>
        <div className="col-span-6 lg:col-span-4">
          <Label className="!font-bold !text-base text-slate-600">
            Email *
          </Label>
          <Input
            className="mb-5 mt-2"
            type="email"
            placeholder="Escribí tu email acá"
            name="email"
            onChange={handleOnChange}
            value={email}
          />
        </div>
        <div className="col-span-6 lg:col-span-4">
          <Label className="!font-bold !text-base text-slate-600">
            Teléfono *
          </Label>
          <Input
            className="mb-5 mt-2"
            type="text"
            placeholder="+54 011"
            name="phone"
            onChange={handleOnChange}
            value={phone}
          />
        </div>
        <div className="col-span-6 lg:col-span-4">
          <Label className="!font-bold !text-base text-slate-600">
            País de residencia
          </Label>
          <Combobox
            className="mb-5 mt-2"
            onChange={handleOnChange}
            data={regions}
            name="country"
            placeholder="Selecciona el país"
          />
        </div>
        <div className="col-span-6 lg:col-span-4">
          <Label className="!font-bold !text-base text-slate-600">
            Profesión
          </Label>
          <Combobox
            className="mb-5 mt-2"
            onChange={handleOnChange}
            data={expertiseOptions}
            name="expertise"
            placeholder="Elige tu profesión"
          />
        </div>
        <div className="col-span-6 lg:col-span-4">
          <Label className="!font-bold !text-base text-slate-600">
            ¿Cómo preferis que te contactemos? *
          </Label>
          <Combobox
            className="mb-5 mt-2"
            onChange={handleOnChange}
            data={preferenceOptions}
            name="preference"
            placeholder="Forma de contacto"
          />
        </div>
        <div className="col-span-6 lg:col-span-4">
          <Label className="!font-bold !text-base text-slate-600">
            Quiero hablar con *
          </Label>
          <Combobox
            className="mb-5 mt-2"
            onChange={handleOnChange}
            data={talkToOptions}
            name="talk_to"
            placeholder="Elige con quien hablar"
          />
        </div>
        <div className="flex flex-col md:col-span-full">
          <span>
            <Label className="!font-bold !text-base text-slate-600">
              Mensaje
            </Label>
          </span>
          <textarea
            className="my-2 h-60 rounded-md border-slate-200 placeholder:text-muted-foreground placeholder:text-slate-400 placeholder:text-base placeholder:font-normal"
            placeholder="Escribe tu mensaje aquí"
            name="message"
            onChange={handleOnChange}
            value={message}
          />
          <span className="text-slate-600 text-base font-normal">
            * campos requeridos
          </span>
        </div>
        <div className="w-full m-5">
          <ReCAPTCHA
            sitekey="6Lfwz_sUAAAAAFHYFO43VhBKoxbEyCUPYfUTnxp8"
            data-theme="dark"
            onChange={handleCaptchaVerification}
            theme="dark"
          />
        </div>
      </div>
      <div className="w-full px-5 pb-10 md:flex md:gap-x-4 lg:w-1/2">
        {state.sending ? (
          <ButtonLoading className="w-full" />
        ) : (
          <Button className="w-full mb-3" type="submit">
            Enviar
          </Button>
        )}
        <Button
          modifier="white"
          className="w-full"
          type="button"
          onClick={handleClearForm}
        >
          Limpiar campos
        </Button>
      </div>
    </form>
  );
};
