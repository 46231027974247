import React from 'react';
import classNames from 'classnames';
import { ButtonT, TextButtonT } from '#interfaces/components';

export const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  TextButtonT & ButtonT
>(
  (
    {
      className,
      children,
      size,
      modifier,
      href = null,
      type = 'button',
      disabled,
      loading,
      fullWidth,
      onClick,
      asChild = false,
      id,
      ...props
    },
    ref,
  ) => {
    const isDisabled = disabled || loading;

    const variants: { [key: string]: any } = {
      warning: 'bg-[#f80a5a] text-white',
      default: 'bg-blue-500 text-white',
      transparent: 'bg-transparent text-blue-600',
      white: 'flex bg-white text-blue-600 shadow-md',
      violet: 'flex bg-violet-500 text-white shadow-md',
      whatsapp: 'text-white bg-[#25d366] flex',
      expansion:
        'w-auto flex items-center cursor-pointer group transition ease-in-out delay-80 hover:translate-x-1',
    };
    const sizes: { [key: string]: any } = {
      small: 'text-xs h-6',
      medium: 'text-xs h-8',
      large: 'text-xs !w-44',
    };
    const classes = classNames(
      'justify-center flex w-auto px-4 bg-black items-center min-w-[18px] outline-none text-base h-10 font-normal leading-none text-center rounded-md hover:shadow-md hover:cursor-pointer hover:-translate-y-0.5 hover:transition-transform hover:duration-300',
      variants[modifier ?? 'default'],
      fullWidth && `justify-center w-full`,
      className,
      size && sizes[size],
      isDisabled &&
        '!bg-gray-400 text-white pointer-events-none border border-gray-400',
    );

    const sharedProps = {
      className:
        modifier && modifier === 'expansion'
          ? classNames(variants.expansion, className)
          : classes,
      disabled: isDisabled,
    };

    const content = asChild ? (
      <span className="w-auto flex text-center items-center">{children}</span>
    ) : (
      <button className="w-full flex items-center justify-center">
        {children}
      </button>
    );

    return href ? (
      <a
        id={id}
        href={href}
        {...sharedProps}
        onClick={onClick}
        ref={ref as React.Ref<HTMLAnchorElement>}
        {...props}
      >
        {content}
      </a>
    ) : (
      <button
        id={id}
        type={type}
        {...sharedProps}
        onClick={onClick}
        ref={ref as React.Ref<HTMLButtonElement>}
        {...props}
      >
        {content}
      </button>
    );
  },
);
