import React from 'react';
import { Layout } from '#hocs/layout';
import { ContactForm } from '#components/organisms';

const ContactView = () => {
  return (
    <Layout section="contact">
      <ContactForm />
    </Layout>
  );
};
export default ContactView;
