import emailjs from '@emailjs/browser';

export const sendEmail = async ({ body }: { body: any }) => {
  try {
    const response = await emailjs.send(
      'email_bilog_sevice',
      'template_mmzgcrs',
      {
        name: body.name,
        to_email: body.email,
        phone: body.phone,
        profession: body.expertise,
        country: body.country,
        preference: body.preference,
        talk_to: body.talk_to,
        message: body.message,
      },
      'YSPBF3opKMeP0uFId',
    );
    return { status: response.status, email: response };
  } catch (err: any) {
    throw new Error(err);
  }
};
