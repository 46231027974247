import React from 'react';
import {
  HiOutlineAcademicCap,
  HiOutlineBanknotes,
  HiOutlineComputerDesktop,
  HiOutlineCursorArrowRipple,
  HiOutlineDevicePhoneMobile,
} from 'react-icons/hi2';
import { IconType } from 'react-icons/lib';
import classNames from 'classnames';
import { Card, Copy, Image } from '#components/atoms';

const CardItem = ({
  children,
  classnames,
}: {
  children: React.ReactNode;
  classnames?: string;
}) => {
  return (
    <Card
      className={classNames(
        'bg-blue-50 !p-8 rounded-md shadow-md my-14 lg:!text-xl',
        classnames,
      )}
    >
      <Copy className="!text-lg">{children}</Copy>
    </Card>
  );
};
function isMobileDevice() {
  return /Mobi|Android/i.test(navigator.userAgent);
}

export const extendTestimonials: { [key: string]: any } = {
  alvarado: {
    header: 'Una de muchas historias',
    title: 'Los 3 factores del éxito de la Clínica Alvarado Careggio.',
    first_item:
      '"El cambio vale la pena", es hoy la reflexión del director de la clínica, sobre lo que fue hace 13 años elegirnos como compañeros de trabajo diario.',
    img: 'alvarado_hero',
    detail_testimonial: {
      img: isMobileDevice() ? null : 'alvarado_1',
      content: (
        <div className="col-span-6">
          <Copy className="!text-base !text-slate-500 mb-10 lg:!text-xl">
            El Dr. Antonio Alvarado, Odontólogo especialista en Rehabilitación
            Oral e Implantes y Director de Clínica Odontológica Alvarado
            Careggio, tenía como objetivo mejorar los procesos administrativos
            de la clínica y por sobre todo ordenar las Historias Clínicas de los
            Pacientes. Antonio y sus colegas querían minimizar el uso del papel
            por todo lo que significaba (desorden, pérdida de tiempo, dinero y
            por ende falta de información tangible sobre la gestión).
          </Copy>
          <Copy className="!text-base !text-slate-500 lg:!text-xl">
            Nos dice:{' '}
            <strong>
              “Buscaba la eliminación del papel, tanto para historias clínicas
              como agendas y demás.”
            </strong>
          </Copy>
        </div>
      ),
    },
    extend_story: {
      img: isMobileDevice() ? 'alvarado_1' : null,
      content: (
        <div className="lg:col-span-12">
          <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
            Luego de determinar las debilidades y él referirse a las
            deficiencias en la gestión diaria, entendimos que a nosotros como
            empresa se nos presentaba un gran desafío: implementar el uso de un
            software en una clínica en funcionamiento, con dueños de gran
            prestigio y trayectoria probada y que tenían toda una manera previa
            de organizarse. Para nosotros, el esfuerzo sería doble.
          </Copy>
          <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
            Nos propusimos trabajar en conjunto hasta lograr un funcionamiento
            aceitado de todos los procesos, que van desde la recepción hasta la
            salida del paciente del consultorio, luego, la facturación y los
            cobros. Para lograrlo fue fundamental el aporte y las críticas de
            los odontólogos experimentados y la contribución del equipo de
            secretarias y administradoras.
          </Copy>
          <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
            Su interés y necesidad de realizar un cambio en la dinámica diaria,
            y nuestra solución propició la transformación tecnológica.
          </Copy>
        </div>
      ),
    },
    more_details: {
      img: isMobileDevice() ? 'alvarado_2' : null,
      content: (
        <div className="lg:col-span-12 lg:grid lg:grid-cols-12 lg:gap-x-6">
          <div className="col-span-6">
            <CardItem classnames="!mt-0 lg:mb-7">
              “Es primordial. No podemos trabajar sin él. Significa orden,
              eficiencia y control. Encontré en Bilog una herramienta que brinda
              todo eso.”
            </CardItem>
            <Copy className="!text-base !text-slate-500 mb-4 lg:mb-7 lg:!text-xl">
              Si tuvieses que recomendar Bilog a un colega, ¿qué le dirías?
            </Copy>
            <CardItem classnames="!mt-0">
              “El cambio vale la pena. Una vez que el sistema está en
              funcionamiento todo el trabajo fluye fácilmente.”
            </CardItem>
          </div>
          <Image
            src={require(`#assets/succes-stories/details/alvarado_2.webp`)}
            alt="device-frame"
            className="hidden lg:block w-full object-cover !h-36 mb-14 lg:col-span-6 lg:!h-[355px] lg:!w-[530px] lg:rounded-md"
          />
          <div className="lg:col-span-12">
            <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
              La organización es indispensable para lograr resultados
              favorables, la transformación digital era el cambio que
              necesitaba, en nuestra herramienta y en nuestro equipo de trabajo
              encontró el mejor aliado. Con el tiempo al ir creciendo fuimos
              implementando nuestros softwares en sus consultorios de Barrio
              Norte en Capital Federal con todos los requerimientos de
              facturación de las prepagas.
            </Copy>
            <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
              Estamos convencidos que la clave del éxito está en acompañar a
              nuestros usuarios en cada paso de la implementación de la solución
              y brindarles tranquilidad y seguridad en la tarea cotidiana y en
              el resguardo de los datos. Entendemos que el corazón del negocio
              es la información y trabajamos para resguardarla.
            </Copy>
          </div>
        </div>
      ),
    },
  },
  marion: {
    header: 'Una historia más para contar',
    title:
      'La estrategia de la Dra. Laura Marion para mejorar el control de su clínica.',
    first_item: '“Bilog es una herramienta que simplifica tareas.”',
    img: 'marion_hero',
    detail_testimonial: {
      img: isMobileDevice() ? null : 'marion_mobile-1',
      content: (
        <div className="col-span-6">
          <Copy className="!text-base !text-slate-500 mb-10 lg:!text-xl">
            <strong>
              Optimización de tiempos y organización en el desarrollo de su
              profesión
            </strong>{' '}
            fue lo que motivó, hace 12 años, a la Dra. Maria Laura Marion a
            elegirnos como compañeros de trabajo.
          </Copy>
          <Copy className="!text-base !text-slate-500 lg:!text-xl">
            Hace más de 10 años, la<strong>Dra. M. Laura Marion</strong> , tenía
            como desafío organizar sus Historias Clínicas, tenerlas a mano,
            saber rápidamente que tenía que hacer al paciente, nos contaba que
            en ese momento tenían la necesidad de organizarse, de digitalizarse,
            de tener control sobre su clínica.
          </Copy>
        </div>
      ),
    },
    extend_story: {
      img: isMobileDevice() ? 'marion_mobile-1' : null,
      content: (
        <div className="lg:col-span-12">
          <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
            Hoy el <strong>Lic. en Administración Diego Pérez</strong>,
            responsable de la Gestión de la Clínica Marion Odontología y
            Estética Dental de la ciudad de Córdoba Capital nos comenta cómo
            ayuda la implementación de nuestro software en sus tareas
            cotidianas.
          </Copy>
          <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
            <strong>
              “Nos ayuda mucho a tener mejor y más centralizada la información.
              También es muy útil a la hora de liquidar los honorarios a los
              profesionales y realizar la facturación de las prepagas y obras
              sociales. Ni hablar de la eficiencia de los recordatorios de
              turnos para minimizar el ausentismo.”
            </strong>
          </Copy>
        </div>
      ),
    },
    more_details: {
      img: isMobileDevice() ? 'marion_mobile-2' : null,
      content: (
        <div className="lg:col-span-12 lg:grid lg:grid-cols-12 lg:gap-x-6">
          <div className="col-span-6">
            <Copy className="!text-base !text-slate-500 mb-4 lg:mb-7 lg:!text-xl">
              Cuando le preguntamos, por qué recomendaría Bilog a sus colegas,
              nos respondio:
            </Copy>
            <CardItem classnames="!mt-0 lg:mb-7">
              "Porque es un software confiable, amigable e intuitivo que tiene
              un buen soporte técnico y que resuelve muy bien la parte
              odontológica. Tiene distintas versiones y módulos que se adaptan
              perfectamente a las necesidades de un profesional o de una clínica
              de mayor tamaño."
            </CardItem>
          </div>
          <Image
            src={require(`#assets/succes-stories/details/marion_mobile-2.webp`)}
            alt="device-frame"
            className="hidden lg:block w-full object-cover !h-36 mb-14 lg:col-span-6 lg:!h-[355px] lg:!w-[530px] lg:rounded-md"
          />
          <div className="lg:col-span-12">
            <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
              Como comentario final, nos dice:
              <strong>
                “No duden, vale la pena y es indispensable hoy en día tener las
                cosas ordenadas en un software que ofrece múltiples soluciones”.
              </strong>
            </Copy>
            <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
              Gracias Maria Laura y Diego por contarnos su experiencia y seguir
              confiando en Bilog todos los días. Nos llena de orgullo trabajar
              codo a codo con profesionales como ustedes.
            </Copy>
          </div>
        </div>
      ),
    },
  },
  orono: {
    header: 'Optimización del tiempo y organización efectiva',
    title: 'El método de las clínicas Oroño Dent.',
    first_item:
      '“Además, el poder contar con acceso a mis datos desde mi celular es de gran utilidad para mantenerme informado siempre sobre mi agenda diaria“.',
    img: 'orono_hero',
    detail_testimonial: {
      img: isMobileDevice() ? null : 'orono_mobile-1',
      content: (
        <div className="col-span-6">
          <Copy className="!text-base !text-slate-500 mb-10 lg:!text-xl">
            Todo comenzó hace más de diez años con la necesidad de una
            herramienta para potenciar y organizar el día a día de sus clínicas.
          </Copy>
          <Copy className="!text-base !text-slate-500 lg:!text-xl">
            Actualmente, las clínicas de los doctores{' '}
            <strong>Lenarduzzi, Roberto y Mauro</strong>, trabajan codo a codo
            con el equipo de Bilog, en la administración de los recursos de las
            clínicas generando un impacto directo en la optimización del tiempo
            y la organización de la clínica.
          </Copy>
          <Copy className="!text-base !text-slate-500 lg:!text-xl">
            Con el objetivo de brindar un servicio odontológico de excelencia,
            fundaron sus dos clínicas llamadas
            <strong>
              {' '}
              Oroño Dent ubicadas en Rosario y San lorenzo, Santa Fé.
            </strong>
          </Copy>
        </div>
      ),
    },
    extend_story: {
      img: isMobileDevice() ? 'orono_mobile-1' : null,
      content: (
        <div className="lg:col-span-12">
          <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
            Son especialistas en cirugía y traumatología buco maxilofacial.{' '}
            <strong>
              Cómo utilizan las clínicas Oroño Dent el software de Bilog para
              potenciar su trabajo?
            </strong>{' '}
            ¿Primero, trabajamos en identificar las problemáticas para
            implementar la herramienta acorde a las necesidades de las clínicas.
            Mauro nos dice: “Al principio el problema fue la organización de las
            agendas y el cúmulo de fichas y papeles”.
            <strong>
              {' '}
              De esta forma trabajamos en implementar una solución integral para
              la administración de las clínicas.
            </strong>
          </Copy>
          <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
            <strong>
              “Nos ayuda mucho a tener mejor y más centralizada la información.
              También es muy útil a la hora de liquidar los honorarios a los
              profesionales y realizar la facturación de las prepagas y obras
              sociales. Ni hablar de la eficiencia de los recordatorios de
              turnos para minimizar el ausentismo.”
            </strong>
          </Copy>
          <CardItem classnames="!mt-0 lg:mb-7">
            “Con el tiempo, y con las actualizaciones del software, descubrimos
            la cantidad de opciones en Bilog que son de utilidad para la
            administración y gestión de nuestras clínicas”. Mauro
          </CardItem>
          <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
            Le preguntamos qué significa Bilog en su trabajo diario, esto fue lo
            que nos contestó:{' '}
            <strong>
              “Hoy es indispensable, podemos llevar un orden en todo el flujo de
              trabajo de nuestra Clínica, en nuestras historias clínicas, las
              agendas, los trabajos de laboratorio, la administración y en las
              liquidaciones a las obras sociales. Nos ayuda a mantenernos
              organizados y a optimizar el tiempo.”
            </strong>
          </Copy>
          <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
            Nuestro objetivo como compañía es ayudar a nuestros usuarios, como
            Mauro, a potenciar y facilitar el trabajo diario, optimizando las
            tareas y los tiempos en los consultorios o clínicas donde están
            implementadas nuestras soluciones. La accesibilidad es algo a lo
            cual prestamos mucha atención y hacemos mucho foco, ya que
            entendemos que vivimos en tiempos donde es primordial el acceso a
            los datos. Mauro nos dice: “Además, el poder contar con acceso a mis
            datos desde mi celular es de gran utilidad para mantenerme informado
            siempre sobre mi agenda diaria“.
          </Copy>
        </div>
      ),
    },
    more_details: {
      img: isMobileDevice() ? 'orono_mobile-2' : null,
      content: (
        <div className="lg:col-span-12 lg:grid lg:grid-cols-12 lg:gap-x-6">
          <div className="col-span-6">
            <Copy className="!text-base !text-slate-500 mb-4 lg:mb-7 lg:!text-xl">
              Entendemos que la mejor forma de contarlo es a través de los
              testimonios de los mismos odontólogos, por eso queremos acercar
              los testimonios de nuestros usuarios, y les pedimos que cuenten a
              sus colegas cómo Bilog transformó y modernizó su día a día. Esto
              fue lo que dijo Mauro:
            </Copy>
            <CardItem classnames="!mt-0 lg:mb-7">
              “Que no solo se va a tener un gran software con el cual van a
              tener organizadas en sus agendas, los va a mantener ordenado
              administrativamente, sino que va a encontrar una excelente
              respuesta y soporte de parte de Bilog.”
            </CardItem>
          </div>
          <Image
            src={require(`#assets/succes-stories/details/orono_mobile-2.webp`)}
            alt="device-frame"
            className="hidden lg:block w-full object-cover !h-36 mb-14 lg:col-span-6 lg:!h-[355px] lg:!w-[530px] lg:rounded-md"
          />
          <Copy className="lg:col-span-12 !text-base !text-slate-500 mb-4 lg:!text-xl">
            Gracias Mauro y Roberto por contarnos su experiencia y seguir
            confiando en Bilog todos los días. Nos llena de orgullo trabajar
            codo a codo con profesionales como ustedes.
          </Copy>
        </div>
      ),
    },
  },
  calcagno: {
    header: 'La fórmula del éxito de Espacio Dental',
    title: 'Trayectoria, motivación y buena comunicación.',
    first_item:
      '“Implementar Bilog nos ayudó a minimizar el ausentismo gracias a los recordatorios y mejoró la optimización de los recursos.”',
    img: 'calcagno-hero',
    detail_testimonial: {
      img: isMobileDevice() ? null : 'calcagno_mobile-1',
      content: (
        <div className="col-span-6">
          <Copy className="!text-base !text-slate-500 mb-10 lg:!text-xl">
            Hablamos con una de nuestras más fieles usuarias, que nosotros
            consideramos una increíble compañera de trabajo. Les queremos contar
            el caso de Liliana Calcagno, odontóloga con una gran trayectoria de
            más de 41 años, especializada en la atención de pacientes con
            discapacidad haciendo foco, sobre todo, en los niños. Hoy, ya hace
            más de 5 años, trabaja en conjunto con su hijo Mauro Martinelli.
          </Copy>
          <Copy className="!text-base !text-slate-500 lg:!text-xl">
            Cuando empezamos a diseñar la implementación de Bilog en su
            consultorio, lo primero que le preguntamos fue cuál era la
            problemática más importante que quería resolver.
          </Copy>
        </div>
      ),
    },
    extend_story: {
      img: isMobileDevice() ? 'calcagno_mobile-1' : null,
      content: (
        <div className="lg:col-span-12">
          <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
            Junto a ella identificamos que
            <strong>
              “no había un seguimiento en los turnos de los pacientes”
            </strong>{' '}
            y esto se traducía directamente en el ausentismo de los pacientes.
            Por eso se decidió desarrollar una estrategia integral que, como
            ella nos dijo,{' '}
            <strong>
              “Implementar Bilog nos ayudó a minimizar el ausentismo gracias a
              los recordatorios y mejoró la optimización de los recursos.”
            </strong>
          </Copy>
          <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
            Le preguntamos qué significa, para ella, Bilog en relación con su
            trabajo diario y, citando, nos dice:{' '}
            <strong>“La mano derecha”</strong>.
          </Copy>
          <Copy className="!text-base !text-slate-500 mb-4 lg:!text-xl">
            Nuestro objetivo como compañía es ayudar a nuestros usuarios, como
            Liliana, a potenciar y facilitar el trabajo diario, optimizando las
            tareas y los tiempos dentro de los consultorios o clínicas donde
            están nuestros software.
          </Copy>
        </div>
      ),
    },
    more_details: {
      img: isMobileDevice() ? 'calcagno_mobile-2' : null,
      content: (
        <div className="lg:col-span-12 lg:grid lg:grid-cols-12 lg:gap-x-6">
          <div className="col-span-6">
            <Copy className="!text-base !text-slate-500 mb-4 lg:mb-7 lg:!text-xl">
              Por eso recurrimos a los testimonios de nuestros usuarios, y les
              pedimos que les cuenten a sus colegas cómo Bilog los ayuda en su
              día a día. Esto fue lo que dijo Liliana:
            </Copy>
            <Copy className="!text-base !text-slate-500 mb-4 lg:mb-7 lg:!text-xl">
              - ¿Qué le dirías a un colega que está pensando en implementar
              Bilog, o que todavía no lo hizo?
            </Copy>
            <CardItem classnames="!mt-0 lg:mb-7">
              ¡Que no dude en realizar la inversión! De hecho lo he
              recomendado.Y también a algunos no odontólogos, por lo bueno del
              sistema, su seriedad y calidez de la atención.
            </CardItem>
          </div>
          <Image
            src={require(`#assets/succes-stories/details/calcagno_mobile-2.webp`)}
            alt="device-frame"
            className="hidden lg:block w-full object-cover !h-36 mb-14 lg:col-span-6 lg:!h-[355px] lg:!w-[530px] lg:rounded-md"
          />
          <Copy className="lg:col-span-12 !text-base !text-slate-500 mb-4 lg:!text-xl">
            Muchas gracias Liliana por darnos tu testimonio y seguir confiando
            en Bilog día a día. Nos llena de orgullo trabajar codo a codo con
            profesionales como vos.
          </Copy>
        </div>
      ),
    },
  },
};
export const filtersButtons: {
  text: string;
  value: string;
  icon: IconType;
  classnames: string;
}[] = [
  {
    text: 'Escritorio',
    value: 'desktop',
    icon: HiOutlineComputerDesktop,
    classnames:
      'data-[state=active]:bg-amber-500 bg-amber-50 text-amber-500 data-[state=active]:!text-white',
  },
  {
    text: 'App web y móvil',
    value: 'web_&_mob',
    icon: HiOutlineDevicePhoneMobile,
    classnames:
      'data-[state=active]:bg-blue-500 bg-blue-50 text-blue-500 data-[state=active]:!text-white',
  },
  {
    text: 'Aprendizaje y ayuda',
    value: 'learning',
    icon: HiOutlineAcademicCap,
    classnames:
      'data-[state=active]:bg-green-500 bg-green-50 text-green-500 data-[state=active]:!text-white',
  },
  {
    text: 'Costos y planes',
    value: 'plans',
    icon: HiOutlineBanknotes,
    classnames:
      'data-[state=active]:bg-violet-500 bg-violet-50 text-violet-500 data-[state=active]:!text-white',
  },
  {
    text: 'Accesos y limitaciones',
    value: 'limitations',
    icon: HiOutlineCursorArrowRipple,
    classnames:
      'data-[state=active]:bg-pink-500 bg-pink-50 text-pink-500 data-[state=active]:!text-white',
  },
];
