import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Title,
  Image,
  Button,
  Card,
  Copy,
  Icons,
  Menu,
  MenuItem,
  MenuSeparator,
} from '#components/atoms';
import { menuData } from '#mocks/menu';
import { useScrollNavigation } from '../../hooks';

export const MobilMenu = () => {
  const handleNavigation = useScrollNavigation();
  const navigate = useNavigate();

  return (
    <Menu className="z-50 lg:hidden">
      <Title>Productos</Title>
      <MenuSeparator />
      {menuData.map((item: any, index: number) => (
        <Menu
          key={index}
          label={item.title}
          subLabel={item.subTitle}
          navLink={item.navLink}
          index={index}
        >
          {item.details?.map(
            (
              item: {
                title: string;
                subTitle: string;
                icon?: string;
                fill?: string;
                logo?: string;
                navigation: string;
              },
              index: number,
            ) => {
              return (
                <MenuItem
                  key={index}
                  index={index}
                  onClick={() => handleNavigation(item.navigation)}
                >
                  <Card className="!p-0">
                    <div className="flex gap-x-4 justify-start w-96">
                      {item.icon ? (
                        <div className="flex flex-col items-center text-top">
                          <Icons
                            type={item.icon}
                            fill={item.fill}
                            className="h-8 w-7 mb-4 text-blue-400"
                          />
                        </div>
                      ) : (
                        <Image
                          src={require(`#assets/icons/${
                            item.logo || 'icon-bilog.png'
                          }`)}
                          width={25}
                          height={50}
                          className="h-6 w-8"
                          alt="icon"
                        />
                      )}
                      <div className="flex flex-col text-left">
                        <Title className="!text-slate-800">{item.title}</Title>
                        <Copy className="mb-4">{item.subTitle}</Copy>
                      </div>
                    </div>
                  </Card>
                </MenuItem>
              );
            },
          )}
        </Menu>
      ))}
      <Button
        modifier="white"
        className="mt-5"
        onClick={() => navigate('/contact')}
      >
        Contáctanos
      </Button>
      <Button
        className="mt-5"
        onClick={() => window.open('https://app.bilog.com.ar', '_blank')}
      >
        Iniciar sesión
      </Button>
    </Menu>
  );
};
