export const items: {
  badge: string;
  description: string;
  className: string;
  img: string;
}[] = [
  {
    badge: 'Odontólogos',
    description:
      'Acceso a historiales médicos, gestión de tratamientos, y herramientas de diagnóstico.',
    className: 'text-violet-600 bg-violet-50',
    img: 'shield-card1.png',
  },
  {
    badge: 'Asistentes',
    description: 'Gestión de citas, atención al cliente, y facturación.',
    className: 'text-amber-600 !bg-amber-50',
    img: 'shield-card2.png',
  },
  {
    badge: 'Administradores',
    description:
      'Acceso a reportes financieros, manejo de caja chica y liquidaciones.',
    className: 'text-green-600 bg-green-50',
    img: 'shield-card3.png',
  },
];
