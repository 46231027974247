import React from 'react';
import { Copy, Title } from '#components/atoms';

export const Terms: React.FC = () => {
  return (
    <div className="mb-20">
      <div className="lg:flex lg:flex-col lg:items-center overflow-hidden px-5 py-12">
        <Title className="m-4 mt-24 text-3xl lg:!text-5xl lg:col-span-full lg:col-start-5 2xl:!text-6xl">
          Condiciones Generales de Uso
        </Title>
      </div>
      <Copy className="m-5 md:mx-24 !font-bold !text-xl">1.</Copy>
      <Copy className="m-5 md:mx-24">
        Estas condiciones generales (las “Condiciones Generales”), resultan de
        aplicación a las personas humanas y/o jurídicas que utilizan el software
        denominado Bilog (en adelante, el “Sistema” y dichas personas humanas
        y/o jurídicas, el “Cliente”). Las estipulaciones contenidas en estas
        Condiciones Generales se podrán en su caso complementar con cualesquiera
        condiciones y/o estipulaciones particulares que surjan de una Propuesta
        Comercial (conforme se define seguidamente) y, conjuntamente a las
        Condiciones Generales rigen la relación contractual vinculada a la
        licencia de uso de Software concedida . Dicha Propuesta Comercial podrá
        no existir en cuyo caso la relación contractual con el Cliente se
        regulará únicamente por estas Condiciones Generales.
      </Copy>
      <Copy className="m-5 md:mx-24 !font-bold !text-xl">2. Definiciones</Copy>
      <Copy className="m-5 md:mx-24">
        Condiciones Generales: son estas condiciones generales de uso del
        Software Bilog. Sistema: es el software Bilog de gestión odontológica,
        auditoría odontológica y de laboratorios. Cliente: es la persona física
        y/o jurídica que ha aceptado estas Condiciones Generales en los términos
        del presente y por ende que utiliza el Sistema. Licenciante: es la
        empresa Bilog SRL, CUIT 30-71728569-3, sociedad debidamente constituida
        conforme las leyes de la República Argentina, con domicilio en José
        Hernández 2276 Piso 4°. Oficina “A”, Ciudad Autónoma de Buenos Aires,
        C.P.1426 Acuerdo: es la relación contractual entre el Cliente y la
        Licenciante, regida por las Condiciones Generales, la Propuesta
        Comercial de haberla y la legislación de fondo aplicable. Propuesta
        Comercial: Es la propuesta de servicios que podrá ser presentada al
        Cliente por el Licenciante conteniendo las diferentes opciones de
        contratación del Sistema y el régimen económico aplicable a ello. Base
        de Datos: Es la base de datos del Cliente cuyo servicio de alojamiento
        está incluido en el precio de la licencia del software. Sitio: es la
        plataforma del Licenciante. En la República Argentina: www.bilog.com.ar;
        en la República Oriental del Uruguay: www.bilog.com.uy
      </Copy>
      <Copy className="m-5 md:mx-24 !font-bold !text-xl">3. Objeto</Copy>
      <Copy className="m-5 md:mx-24">
        3.1. El objeto de este Acuerdo es el licenciamiento del Sistema de
        propiedad intelectual de la Licenciante en los términos ofrecidos en
        estas Condiciones Generales y la prestación del servicio de alojamiento
        de la base de datos del Cliente.
        <br />
        <br /> 3.2. Toda mención que se haga en estas Condiciones Generales al
        Acuerdo incluye este Acuerdo original y todas y cualesquiera acuerdos
        modificatorios adicionales, sean ampliatorios, modificatorios y/o
        reductores de productos y/o servicios. Asimismo, el Cliente declara
        conocer y aceptar que el alcance del Acuerdo podrá ajustarse de tanto en
        tanto , quedando determinado mes a mes, según el alcance de la factura
        emitida por la Licenciante, aceptada por el Cliente.
        <br />
        <br /> 3.3. Las licencias objeto de este Acuerdo, se otorgan con
        carácter intransferible y no exclusivo, para uso exclusivo del Cliente a
        partir de su instalación y operación desde un servidor interno y en la
        infraestructura informática de propiedad del Cliente, quedándole
        prohibido utilizarlas para fines no previstos en este Acuerdo,
        incluyéndose – pero no limitado a – la prohibición de uso del Sistema
        con terceros o para terceros, salvo autorización expresa de la
        Licenciante. Asimismo, las licencias de utilización del Sistema se
        otorgan limitadas y condicionadas al cumplimiento de todos y cada uno de
        los términos y condiciones que regulan la relación contractual, por todo
        el plazo de vigencia establecido, con derecho de utilización del
        Sistema, solamente, en formato de código objeto.
        <br />
        <br />
        3.4. El Sistema ofrecido por el Licenciante al Cliente consiste en una
        modalidad gratuita (FREEMIUM) por un lado y en una modalidad paga
        (PREMIUM) por el otro, conforme surge de estas Condiciones Generales y
        del Sitio.
        <br />
        <br /> 3.5. Modalidad Gratuita (FREEMIUM) y modalidad paga (PREMIUM) El
        Cliente que opte por la modalidad gratuita del Sistema dispondrá de una
        licencia de uso gratuita de los servicios del Sistema (con el alcance
        indicado seguidamente) mientras esté dado de alta en el Sitio y no
        solicite la cancelación de la misma en los términos de la Cláusula 12.1
        de estas Condiciones Generales. La contratación de esta modalidad
        gratuita se realizará a través del Sitio. Se considerará que el Cliente
        conoce y acepta el alcance de los servicios de la modalidad gratuita,
        así como estas Condiciones Generales, mediante el alta del Cliente a
        través del Sitio. El alcance de los servicios del Sistema bajo la
        modalidad gratuita es el que surge del siguiente link. El Licenciante se
        reserva el derecho de modificar las condiciones de la modalidad gratuita
        previa comunicación a los Clientes. Por otro lado, el Cliente que opte
        por la modalidad paga del Sistema, dispondrá de una licencia de uso
        exclusiva e intransferible de los servicios del Sistema (con el alcance
        indicado seguidamente) mientras no cancele dicha contratación en los
        términos indicados en la Cláusula 12.1 de estas Condiciones Generales.
        La contratación de esta modalidad paga se realizará a través del Sitio.
        Se considerará que el Cliente conoce y acepta el alcance de los
        servicios de la modalidad paga, así como estas Condiciones Generales,
        mediante el alta del Cliente a través del Sitio. una comunicación de
        este último al Licenciante. El alcance de los servicios del Sistema bajo
        la modalidad paga es la que surge del siguiente link. Esta modalidad
        paga se prorrogará mensualmente en forma ilimitada y automática, excepto
        que el Cliente la cancele en los términos indicados en la Cláusula 12.1
        de estas Condiciones Generales. El Licenciante se reserva el derecho de
        modificar las condiciones de la modalidad paga (incluyendo sin
        limitación, el precio aplicable) previa comunicación a los Clientes. Se
        considerará que el Cliente acepta dichas modificaciones mediante el pago
        de la factura correspondiente al mes siguiente al de la comunicación de
        la modificación.
      </Copy>
      <Copy className="m-5 md:mx-24 !font-bold !text-xl">6. Garantías</Copy>
      <Copy className="m-5 md:mx-24">
        La presente cláusula describe las garantías otorgadas por la Licenciante
        con el siguiente alcance: <br />
        <br />
        6.1.- Debido a la naturaleza del Sistema, las particularidades de su
        operación y la interacción con servicios de terceros (vgr. Energía
        eléctrica, conectividad a internet, etc.), la Licenciante no puede
        garantizar que su funcionamiento será libre de interrupciones o que no
        existirán errores en su operación. No obstante, la Licenciante garantiza
        que el Sistema funcionará – en lo sustancial – de conformidad con las
        especificaciones, por un plazo de treinta (30) días contados desde la
        terminación de la implementación, siempre y cuando se observen las
        siguientes condiciones: (i) la Licenciante preste el servicio de
        mantenimiento, bajo su programa de servicio post venta permanente; (ii)
        el Sistema se utilice en forma apropiada, de acuerdo a las
        especificaciones propias del mismo, del manual de usuario y de
        conformidad con estas condiciones; (iii) en su caso, se implanten de
        forma apropiada todas las actualizaciones y nuevas versiones del Sistema
        que se originen; y (iv) no se realice ninguna alteración, modificación o
        adición al Sistema sin el previo consentimiento por escrito de la
        Licenciante.
        <br />
        <br /> 6.2.- Errores o fallas: En caso de que el Cliente notifique la
        ocurrencia de un error o la manifestación de una falla oculta, la
        Licenciante, previa evaluación del problema, acordará con el Cliente el
        plazo para efectuar la reparación de esos errores o fallas ocultas, sin
        perjuicio de lo cual, la Licenciante empleará todos los recursos
        razonables para efectuar las reparaciones lo antes posible, en los
        términos del mantenimiento contratado por el Cliente.
        <br />
        <br /> 6.3.- La garantía no será aplicable a condiciones resultantes de
        uso inadecuado, accidente, negligencia o cualquier otra causa externa al
        Sistema, incluidos, pero sin limitarse a, instalación, servicio o
        modificaciones no ejecutadas por la Licenciante o su operación fuera de
        los parámetros especificados por la Licenciante.
        <br />
        <br /> 6.4.- La garantía aquí establecida es la única ofrecida por la
        Licenciante con relación a errores o fallas ocultas del Sistema. Vencida
        la garantía, la prestación de los servicios de mantenimiento preventivo
        y correctivo se prestará de acuerdo a las condiciones de contratación de
        tales servicios.
      </Copy>
      <Copy className="m-5 md:mx-24 !font-bold !text-xl">
        7. Funcionamiento
      </Copy>
      <Copy className="m-5 md:mx-24">
        7.1.- El Sistema, desarrollado con tecnología propia de la Licenciante,
        recopilará automáticamente determinados datos relacionados con el
        hardware y el software del sistema operativo de los equipos que el
        Cliente conecte al Sistema. El Sistema utilizará esta información para
        el funcionamiento del servicio, mantener su calidad y su seguridad.
        <br />
        <br /> 7.2.- Consecuentemente, la Licenciante no será responsable por la
        compatibilidad del Sistema con otro software no producido o suministrado
        por ella o por hardware que no haya especificado expresamente. Sin
        embargo, la Licenciante informará los requerimientos en materia de
        sistemas operativos, bases de datos y demás software de infraestructura
        y escritorio a fin de que el Cliente verifique la compatibilidad con el
        Sistema. Los requisitos de infraestructura local actuales para asegurar
        un adecuado funcionamiento son que la PC, notebook o terminal en donde
        se instale, tenga – como mínimo - , Windows 7 o Windows 10. La
        Licenciante se reserva el derecho de discontinuar la prestación de
        servicios sobre versiones que corran sobre dicha infraestructura,
        renunciando el Cliente a iniciar acciones por dicha decisión y,
        obligándose, en caso de pretender seguir contando con el uso del Sistema
        y los servicios de la Licenciante, evolucionar en infraestructura y
        tecnología para poder soportar las nuevas versiones del Sistema y/o
        adaptar la utilización del Sistema a formas alternativas de
        comercialización propuestas por la Licenciante; eventualmente, el
        Cliente tendrá derecho a dar de baja la contratación, preavisando su
        voluntad en tal sentido con treinta (30) días de anticipación..
      </Copy>
      <Copy className="m-5 md:mx-24 !font-bold !text-xl">8. Precio</Copy>
      <Copy className="m-5 md:mx-24">
        8.1.- El precio de la modalidad paga (Premium) del Sistema, incluyendo
        las licencias y servicios vinculados, es el descripto en el Sitio y en
        caso de existir conforme fuere indicado, en la Propuesta Comercial. El
        precio es neto. Consecuentemente, en caso de corresponder, serán a cargo
        exclusivo del Cliente y serán adicionado a las facturas
        correspondientes, los siguientes conceptos: (i) el Impuesto al valor
        agregado (IVA o su equivalente) y/o cualquier otro cualquiera que lo
        sustituya en el futuro y/o cualquier impuesto, tasa, carga o
        contribución específicos que se pudieren imponer en el futuro a la
        Licenciante por la prestación de los servicios expresados en estas
        Condiciones Generales; y (ii) los cargos por comisiones bancarias y
        cualquier otro costo derivado del pago internacional mediante
        transferencia bancaria, los que serán cargados en la factura emitida en
        el período inmediatamente posterior al de la recepción del pago.
        <br />
        <br /> 8.2.- Los servicios se facturarán mensualmente por adelantado y
        el Cliente realizará el pago del precio antes del día 10 de cada mes,
        bajo apercibimiento de incurrir el Cliente en mora. Las facturas podrán
        ser emitidas por el Licenciante y/o bien por una sociedad relacionada a
        la misma y/o a sus accionistas.
        <br />
        <br /> 8.3.- El precio incluye los conceptos descritos en el Sitio. Todo
        concepto no incluido (p.e. licencias de acceso adicionales, habilitación
        de módulos adicionales, etc.), requerirá de expresa comunicación al
        Cliente y aceptación por parte del del Cliente por cualquier medio, la
        que se considerará aceptada por la Licenciante con la emisión de la
        factura mensual correspondiente y/o facturación específica de tales
        conceptos solicitados en los casos en que no se hubiere pactado
        facturación periódica mensual. <br />
        <br /> 8.4.- Adicionalmente, y a efectos de evitar diferencias de
        interpretación, queda expresamente aclarado que los términos de
        prestación de los servicios no incluyen: (i) La atención de nuevos
        requerimientos de servicios de implementación por transición, migración,
        instalación, prueba o cualesquiera otros servicios solicitados por el
        Cliente por la implementación de actualizaciones o nuevas versiones del
        Sistema y/o adquisición de nuevas terminales informáticas. Los mismos
        serán cotizados oportunamente y cobrados por separado. (ii) Asimismo, en
        caso de que una actualización o nueva versión exija nuevas versiones de
        productos de terceros (vgr. entorno operativo, administradores de bases
        de datos, red y demás softwares vinculados), los precios de dichas
        licencias e implementaciones serán responsabilidad del Cliente. Si
        pudieren ser realizados por la Licenciante, serán objeto de cotización
        específica. (iii) Finalmente, en caso que el Cliente modifique su
        entorno operativo, administradores de bases de datos, red y demás
        software o cualquier producto de terceros, a su exclusivo criterio, la
        Licenciante podrá optar por cotizar los servicios necesarios a la
        adaptación del Sistema cobrando tales servicios por separado o por no
        realizar adaptación alguna corriendo por cuenta del Cliente la
        responsabilidad respecto al adecuado funcionamiento del Sistema. <br />
        <br /> 8.5.- Los precios establecidos en el Sitio y/o en caso de
        existir, en la Propuesta Comercial, , podrán ser modificados por la
        Licenciante.La Licenciante informará al Cliente la modificación en el
        precio. Se considerará que el Cliente acepta dicha modificación mediante
        el pago de la factura correspondiente al mes siguiente al de la
        comunicación de la modificación.. En caso de mediar objeciones del
        Cliente, la Licenciante podrá optar por dar por terminada la relación
        contractual, sin más obligación que hacer entrega de la base de datos de
        propiedad del Cliente en formato de archivo plano txt. <br />
        <br /> 8.6.- En caso que el Cliente resida fuera de la República
        Argentina, los servicios será cotizados y facturados en dólares
        estadounidenses.
      </Copy>
      <Copy className="m-5 md:mx-24 !font-bold !text-xl">
        9. Servicio de implementación
      </Copy>
      <Copy className="m-5 md:mx-24">
        9.1.- Consiste en la gestión del proyecto de puesta en marcha del
        Sistema, de manera coordinada y consensuada con el Cliente o responsable
        del proyecto asignado por el Cliente. El servicio incluye la
        capacitación del personal siguiendo la planificación establecida para
        esta actividad. El objetivo es lograr una capacitación de calidad para
        el personal afectado a la utilización del Sistema e incluye el
        asesoramiento en el uso del equipamiento necesario. El precio del
        servicio de implementación, en caso de cotizarse por separado, surge de
        la Propuesta Comercial, y salvo expresa mención en contrario, se abonará
        por adelantado y por única vez, dentro de las cuarenta y ocho (48) horas
        de aceptada la Propuesta Comercial.
      </Copy>
      <Copy className="m-5 md:mx-24 !font-bold !text-xl">10. Mora</Copy>
      <Copy className="m-5 md:mx-24">
        10.1.- La mora en los pagos debidos por el Cliente se producirá en forma
        automática, de pleno derecho, por el mero transcurso de los plazos y sin
        necesidad de interpelación alguna. Operada la mora, la Licenciante podrá
        optar por: a) Exigir al Cliente el pago del canon con más una tasa del
        tres por ciento (3 %) mensual sobre el importe adeudado, vencido e
        impago de que se trate por cada día de mora y hasta la efectiva
        cancelación de las cantidades debidas. Solo se considerará cancelada la
        deuda en mora si en forma conjunta el Cliente abona, el monto facturado
        con más sus intereses; esto, aún cuando el Cliente hubiera recibido el
        pago sin formular reserva alguna en ese sentido. b) Resolver la relación
        contractual derivada de la aceptación de la Propuesta Comercial de pleno
        derecho y exigir el pago de los daños y perjuicios que el incumplimiento
        le irrogara.
        <br />
        <br /> 10.2.- Sin perjuicio de lo determinado anteriormente, en caso que
        el Cliente omitiera realizar cualquier pago en las fechas acordada, la
        Licenciante podrá, sin necesidad de cualquier aviso o notificación: (i)
        tomar las medidas necesarias para el cobro del monto adeudado, debiendo
        el Cliente pagar los gastos de cobranza, incluso gastos de intimación y
        honorarios de abogados incurridos por la Licenciante, estipulados en
        diez por ciento (10%); (ii) en caso de atraso superior a 10 días,
        suspender la prestación de servicios que se esté realizando conforme a
        la relación contractual derivada de la aceptación de la Oferta, hasta
        que se liquiden los valores en atraso; y (iii) en caso el atraso sea
        superior a 30 días, el Cliente perderá automáticamente, hasta tanto se
        liquiden los valores en atraso, el derecho a utilizar el Sistema. El uso
        del Sistema en ese período configurará infracción a los derechos de
        propiedad intelectual de la Licenciante.
        <br />
        <br /> 10.3.- En la hipótesis de cualquier atraso en el cronograma
        estipulado por las Partes para la implementación del Sistema que sea
        imputable al Cliente, éste se obliga a: (i) realizar el pago de los
        valores adeudados en las fechas inicialmente establecidas en el
        cronograma, como si el evento previsto para fines de facturación hubiera
        ocurrido; (ii) reembolsar a la Licenciante los costos y gastos
        referentes a la asignación de personal por tiempo adicional al
        inicialmente previsto.
      </Copy>
      <Copy className="m-5 md:mx-24 !font-bold !text-xl">
        11. Mantenimiento y Mesa de Ayuda
      </Copy>
      <Copy className="m-5 md:mx-24">
        11.1.- Mantenimiento <br />
        <br /> 11.1.1.- La Licenciante a su exclusivo criterio podrá ofrecer al
        Cliente en la Propuesta Comercial un servicio técnico de mantenimiento
        que incluye tareas de actualización de las funcionalidades contratadas,
        en tanto y en cuanto el Cliente no se encuentre en mora con los pagos
        debidos a la Licenciante conforme al alcance derivado de la Propuesta
        Comercial. <br />
        <br /> 11.1.2.- La Licenciante queda facultada a realizar inspecciones
        en las oficinas del Cliente para verificar el cumplimiento de éste con
        las obligaciones asumidas en virtud de la relación contractual. <br />
        <br /> 11.1.3.- Sobre el particular, el Cliente se obliga: (i) a pagar
        el precio que se determine; (ii) cumplir sus obligaciones de acuerdo con
        las necesidades de implementación cuando fuere aplicable; (iii) cooperar
        con la Licenciante y colocar a su disposición los equipamientos,
        instalaciones y personal que sean necesarios para la ejecución de las
        tareas. <br />
        <br /> 11.2.- Mesa de Ayuda
        <br />
        <br />
        11.2.1.- La Licenciante, a su exclusivo criterio y conforme lo acuerde
        en la Propuesta Comercial, podrá prestar el servicio de mesa de ayuda a
        través de su departamento de ayuda y soporte técnico. En caso que así se
        acuerde en la Propuesta Comercial, la Licenciante podrá afectar un
        referente técnico dependiente de la Licenciante que quedará afectado en
        forma exclusiva al Cliente, debiendo el Cliente asumir el costo de dicho
        recurso humano.
        <br />
        <br /> 11.2.2.- Este servicio incluye tareas de asesoramiento en la
        configuración del Sistema y/o apoyo en el análisis de métodos a aplicar
        en el Sistema, en el horario de lunes a viernes de 10 a 18. La
        Licenciante pone a disposición del Cliente el email info@bilog.com.ar,
        para comunicación de incidentes, obligándose la Licenciante a dar
        respuesta dentro de las siguientes ocho (8) horas hábiles. Realizada una
        consulta, y de acuerdo con el diagnóstico que se realice, la Licenciante
        garantiza presencia de personal adecuado para ofrecer una solución,
        dentro del plazo comprometido en el referido diagnóstico.
      </Copy>
      <Copy className="m-5 md:mx-24 !font-bold !text-xl">
        12. Vigencia. Terminación
      </Copy>
      <Copy className="m-5 md:mx-24">
        12.1.- La relación contractual derivada de la aceptación de estas
        Condiciones Generales (incluyendo la Propuesta Comercial, de
        corresponder) estará en vigencia por tiempo indeterminado, hasta tanto
        alguna de las Partes notifique su voluntad de dar por terminada la
        relación contractual, en el sentido indicado seguidamente. Si el Cliente
        solicita la terminación del Acuerdo, mediando solicitud del Cliente, la
        Licenciante quedará obligada a hacer entrega de la base de datos del
        Cliente en formato plano txt. y el Cliente quedará obligado a cancelar
        toda y cualquier cantidad debida devengada durante la vigencia
        contractual.Cancelación del Sistema bajo modalidad paga (Premium). El
        Cliente que haya contratado la modalidad paga del Sistema podrá
        solicitar la cancelación del servicio enviando un correo electrónico a
        bajas@bilog.com.ary siempre antes de los 10 días iniciales de cada mes.
        En el plazo máximo de 72 horas el Cliente recibirá un correo electrónico
        en la dirección de correo electrónico facilitada oportunamente por el
        Cliente donde encontrará las condiciones en las que se hará efectiva la
        baja. En el momento que la baja sea efectivizada en el sentido indicado,
        el Cliente dejará de tener acceso a los servicios del Sistema y no podrá
        volver a utilizarlos en la modalidad paga, excepto que los contrate
        nuevamente. Cancelado el Sistema pago, el Cliente podrá acceder a la
        modalidad gratuita del Sistema con el alcance que dicha modalidad tiene
        conforme se mencionó en estas Condiciones Generales. La cancelación del
        servicio bajo la modalidad paga será efectiva a partir del mes siguiente
        al mes en que haya sido solicitada la cancelación, por lo que el precio
        abonado hasta dicho momento, incluyendo el devengado durante el mes en
        el que la cancelación haya sido solicitada, quedará en poder del
        Licenciante y no estará sujeto a devolución alguna. Cancelación del
        Sistema bajo modalidad gratuita (Freemium). El Cliente que haya
        contratado la modalidad gratuita del Sistema podrá solicitar la
        cancelación del servicio enviando un correo electrónico a
        bajas@bilog.com.ar. Solicitada la baja por parte del Cliente,
        automáticamente dejará de tener acceso a los servicios del Sistema y no
        podrá volver a acceder a los mismos, excepto que los contrate
        nuevamente. Si un Cliente utiliza los servicios del Sistema tanto bajo
        la modalidad gratis (Freemium) como la paga (Premium), al solicitar la
        cancelación de la modalidad gratuita, estará solicitando también la
        cancelación de la modalidad paga en el sentido indicado anteriormente.{' '}
        <br />
        <br />
        12.2.- Asimismo, la Licenciante podrá rescindir el Acuerdo, con efectos
        inmediatos, en caso de que el Cliente fuere declarado en concurso
        preventivo o en quiebra, o si se trabase embargo, inhibición o cualquier
        otra medida cautelar sobre los bienes del Cliente, en caso de
        transferencia del fondo de comercio por parte del Cliente; cambio de
        control accionario y/o su fusión o escisión o en caso de que el Cliente
        no cumpla con alguna de las obligaciones contractuales, previa
        intimación – en este último caso – al cumplimiento por un período de
        cinco (5) días hábiles. <br />
        <br /> 12.3.- En todos los casos, la Licenciante tendrá el derecho de
        impedir el acceso del Cliente a su base de datos, limitándose el derecho
        del Cliente a solicitar la descarga y entrega de estos, en el formato
        acordado. Operada la rescisión, el Cliente deberá cesar en el uso del
        Sistema a partir de la fecha de terminación propuesta en la comunicación
        fehaciente de rescisión cursada por la Licenciante, sin derecho del
        Cliente a exigir indemnización alguna y con obligación de restituir a la
        Licenciante el material que le hubiera sido entregado por la Licenciante
        para la utilización del Sistema, sin perjuicio del derecho de la
        Licenciante a accionar por los daños y perjuicios que dicho
        incumplimiento le hubiere generado.
      </Copy>
      <Copy className="m-5 md:mx-24 !font-bold !text-xl">
        13. Confidencialidad
      </Copy>
      <Copy className="m-5 md:mx-24">
        Las Partes mantendrán confidencialidad sobre todos los datos e
        informaciones de la otra que le hayan suministrado o a que tengan acceso
        como resultado de la relación contractual derivada de la aceptación de
        la Propuesta Comercial, durante su vigencia y por 5 años después de su
        término. No obstante, la Licenciante podrá, con la previa autorización
        del Cliente, divulgar la existencia de la aceptación de la Propuesta
        Comercial para fines comerciales, mencionando nombre y marca del Cliente
        en campañas y en su material de divulgación.
      </Copy>
      <Copy className="m-5 md:mx-24 !font-bold !text-xl">14. Indemnidad</Copy>
      <Copy className="m-5 md:mx-24">
        El Cliente mantendrá indemne a la Licenciante por y frente a cualquier
        gasto, costo, multa, daño, incluyendo intereses, costas y honorarios de
        letrados derivados del mal uso del Sistema por parte del Cliente y/o del
        uso que éste dé al Sistema fuera de las facultades brindadas por el
        Acuerdo. No será responsable la Licenciante de ningún tipo de
        contingencia que pueda derivarse al Cliente y/o a terceros por el uso
        del Sistema. Asimismo, éste mantendrá indemne a la Licenciante respecto
        a cualquier reclamo de índole laboral que éste pudiera sufrir en
        relación con el personal del Cliente vinculado, o no, a la prestación
        del servicio. Especialmente, el Cliente – en su calidad de titular de la
        Base de Datos – se compromete a dar cumplimiento a la legislación
        aplicable en materia de protección de datos personales, brindando a los
        titulares de los datos personales derecho de acceso, consulta,
        rectificación, supresión y cualesquiera otros que pudieran derivarse de
        la citada normativa. En cualquier caso, el Cliente se obliga a mantener
        indemne a la Licenciante por y frente a cualquier infracción que
        cometiere tanto en la recolección y administración de datos personales,
        como en el respeto de los derechos de los titulares de los datos
        personales, en los términos de la citada legislación aplicable,
        declarando y garantizando a la Licenciante que cuenta con derechos
        suficientes para su recolección y alojamiento bajo el servicio prestado
        merced a este Acuerdo.
      </Copy>
      <Copy className="m-5 md:mx-24 !font-bold !text-xl">15. Varias</Copy>
      <Copy className="m-5 md:mx-24">
        15.1.- Reclutamiento de Personal: Las Partes están de acuerdo en no
        contratar empleados o prestadores de servicio de la otra parte durante
        la vigencia de la relación contractual derivada de la aceptación de la
        Propuesta Comercial y por un período de 12 (doce) meses después de su
        término. La Parte que no cumpla esta cláusula pagará multa equivalente
        al valor total pagado a dicho empleado o prestador de servicio durante
        los seis (6) meses precedentes a su contratación.
        <br />
        <br /> 15.2.- Cesión y Subcontratación: Los derechos y obligaciones
        emergentes de la aceptación de la Propuesta Comercial no pueden ser
        cedidos por cualquiera de las Partes sin el previo consentimiento por
        escrito de la otra Parte. 15.3.- Inexistencia de relación de consumo: El
        Cliente reconoce que el Sistema será utilizado como herramienta para la
        ejecución de sus actividades profesionales y/o empresariales y que, por
        lo tanto, no existe relación de consumo de ella con la Licenciante.
        <br />
        <br /> 15.4.- Partes Independientes: La relación contractual derivada de
        la aceptación de la Propuesta Comercial no constituye sociedad,
        asociación ni figura asociativa alguna, joint venture o contrato de
        colaboración empresaria; asimismo, no constituye relación laboral entre
        las Partes. Como consecuencia de ello, cada una de las Partes es
        exclusiva responsable de dar cumplimiento a la totalidad de las
        obligaciones laborales a su cargo, incluyendo las obligaciones
        previsionales y de la seguridad social, obligándose – asimismo – a
        mantener indemne a la otra Parte por y frente a cualquier reclamo,
        acción y/o sanción que dicha otra Parte recibiere con causa o título en
        incumplimientos en esta materia. <br />
        <br />
        15.5.- Nulidad Parcial: En caso de que alguna de las cláusulas de este
        Acuerdo deviniere nula o inválida, las restantes cláusulas de las mismas
        conservarán su vigor, no pudiéndose solicitar la resolución de la
        relación contractual con dicho fundamento, salvo que dicha cláusula
        afectara a los elementos esenciales de la contratación. 15.6.-
        Encabezados: Los títulos de las cláusulas y apartados son meramente
        referenciales y al solo efecto expositivo.
      </Copy>
      <Copy className="m-5 md:mx-24 !font-bold !text-xl">
        16. Domicilios y Jurisdicción
      </Copy>
      <Copy className="m-5 md:mx-24">
        16.1.- A todos los efectos legales derivados de este Acuerdo, las Partes
        constituyen domicilio en los indicados en la Propuesta Comercial
        aplicable. En tales domicilios, se tendrán por válidas y eficaces todas
        las notificaciones.
        <br />
        <br /> 16.2.- Asimismo, las Partes se someten a la jurisdicción y
        competencia de la Justicia Ordinaria de la Capital Federal, con renuncia
        expresa a cualquier otro fuero y/o jurisdicción que pudiere
        corresponderles. Productos Odontología Laboratorios Auditoría Compañía
        Contacto Nosotros Testimonios Términos y condiciones Privacidad Ayuda
      </Copy>
    </div>
  );
};
