import React from 'react';
import classNames from 'classnames';
import { CopyT } from '#interfaces/components';

export const Copy: React.FC<CopyT> = ({ id, children, className = 'mt-0' }) => {
  const styles = classNames('text-sm font-normal text-slate-600', className);
  return (
    <p id={id} className={styles}>
      {children}
    </p>
  );
};
