export const FooterData: {
  [key: string]: { [key: string]: string | null | boolean }[];
} = {
  Productos: [
    { name: 'Gestión odontológica', link: '/schedule', is_external: false },
    { name: 'Auditoría odontológica', link: '/auditory', is_external: false },
  ],
  Nosotros: [
    { name: 'Historia', link: '/about', is_external: false },
    { name: 'Testimonios', link: '/testimonials', is_external: false },
  ],
  Recursos: [
    { name: 'Preguntas frecuentes', link: '/faqs', is_external: false },
    {
      name: 'Privacidad',
      link: 'https://payments.bilog.com.ar/privacy',
      is_external: true,
    },
    {
      name: 'Terminos y condiciones',
      link: '/terms_and_conditions',
      is_external: false,
    },
  ],
  Consultas: [
    { name: 'Contacto', link: '/contact', is_external: false },
    {
      name: 'Tarifas',
      link: 'https://payments.bilog.com.ar',
      is_external: true,
    },
  ],
};
