import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ListT } from '#interfaces/components';
import { Title } from '#components/atoms';

export const List: React.FC<ListT> = ({ data }) => {
  const columns = Object.keys(data);
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-2 lg:grid-cols-12 overflow-x-hidden lg:mt-2 relative z-10">
      {columns.map((column, index) => (
        <div key={index} className="p-2 ml-4 lg:col-span-3">
          <Title>{column}</Title>
          <ul>
            {data[column].map((item: any, index: number) => (
              <li key={index} className="my-5">
                <span
                  className="text-sm font-normal text-slate-600 hover:text-blue-400 cursor-pointer"
                  onClick={() =>
                    item.is_external
                      ? window.open(item.link, '_self')
                      : navigate(item.link)
                  }
                >
                  {item.name}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};
