import React from 'react';
import { ImageT } from '#interfaces/components';

export const Image: React.FC<ImageT> = ({
  width,
  height,
  className,
  src,
  alt,
  onclick,
}) => {
  return (
    <img
      id="logo"
      className={className}
      src={src}
      alt={alt || 'img'}
      width={width}
      height={height}
      onClick={onclick}
    />
  );
};
