import React from 'react';
import {
  Card,
  Carousel,
  CarouselContent,
  CarouselItem,
  Copy,
  Title,
} from '#components/atoms';
import { TestimonialContainer } from '#components/molecules';
import { testimonialsData } from '#mocks/testimonials';

export const Testimonial: React.FC = () => {
  return (
    <Card className="flex overflow-hidden flex-col lg:!justify-start !py-10 lg:items-center">
      <Title className="text-3xl lg:text-5xl lg:text-center mb-2 lg:mt-20 2xl:text-6xl">
        Historias como la tuya
      </Title>
      <Copy
        className="mb-5 
      text-xl text-slate-900 2xl:text-2xl"
      >
        Miles de profesionales como vos eligen y siguen eligiendo a Bilog
      </Copy>
      <Carousel autoPlay>
        <CarouselContent>
          {testimonialsData.map((item, index) => (
            <CarouselItem
              key={index}
              className="!basis-5/6 md:!basis-2/3 lg:!basis-[24%] max-w-lg"
            >
              <TestimonialContainer
                avatar={item.avatar}
                userName={item.userName}
                testimonialText={item.testimonialText}
                proffesion={item.proffesion}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </Card>
  );
};
