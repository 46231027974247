import React from 'react';
import {
  Button,
  Card,
  Carousel,
  CarouselContent,
  CarouselItem,
  Copy,
  Icons,
  Image,
  Title,
} from '#components/atoms';
import { StackTestimonials } from '#components/organisms';
import { stories } from '#mocks/stories';
import { useNavigate } from 'react-router-dom';

export const Stories = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div className="p-5 hidden md:block !pb-10 md:px-24 lg:my-28 lg:px-24">
      <Title className="text-3xl mb-4 lg:mt-20 md:text-center lg:text-5xl lg:mb-7 2xl:text-6xl">
        {title}
      </Title>
      <Copy className="mb-8 text-xl md:text-center lg:hidden">
        {description}
      </Copy>
      <Carousel className="md:hidden" isVisibleDots={false} autoPlay>
        <CarouselContent>
          {stories?.map((story: any, index: number) => (
            <CarouselItem key={index} className="basis-3/4">
              <Item
                title={story.title}
                description={story.description}
                img={story.img}
                id={story.id}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
      <StackTestimonials />
    </div>
  );
};

const Item = ({
  title,
  description,
  img,
  id,
}: {
  title: string;
  description: string;
  img: string;
  id: string;
}) => {
  const navigate = useNavigate();

  return (
    <Card className="flex-col m-1 !p-0 rounded-md !max-h-[376px] shadow-md">
      <Image
        src={require(`#assets/succes-stories/${img}.webp`)}
        className="w-full rounded-t-md !h-32"
        alt="story"
      />
      <div className="-mt-2 p-5 rounded-md bg-white !max-h-[250px]">
        <Title className="text-lg mb-4 !text-slate-900">{title}</Title>
        <Copy className="!text-slate-500 mb-4">{description}</Copy>
        <Button
          modifier="expansion"
          asChild
          onClick={() => navigate(`/testimonials/${id}`)}
        >
          <p className="text-blue-500 text-left text-base font-regular whitespace-nowrap group-hover:font-bold pr-4 py-2">
            Ver más
          </p>
          <Icons
            className="text-blue-500 h-4 font-regular group-hover:stroke-[3]"
            type="outlineArrowRight"
          />
        </Button>
      </div>
    </Card>
  );
};
