import React from 'react';
import { Card, Copy, Image, Title } from '#components/atoms';

export const TestimonialContainer: React.FC<{
  avatar: string;
  userName: string;
  testimonialText: string;
  proffesion: string;
}> = ({ avatar, userName, testimonialText, proffesion }) => {
  return (
    <Card className="flex flex-col shadow-md rounded-md my-10 bg-white">
      <Card className="!p-0 justify-between">
        <div className="flex gap-x-5">
          <Image
            src={require(`#assets/testimonials-avatars/${avatar}`)}
            width={50}
            height={100}
            className="rounded-full bg-slate-700 h-12"
            alt="testimonial avatar"
          />
          <div>
            <Title className="2xl:text-2xl">{userName}</Title>
            <Copy className="!text-blue-400">{proffesion}</Copy>
          </div>
        </div>
      </Card>
      <div className="mt-2 mb-2 h-0 w-full border-t border-dotted border-gray-300" />
      <Copy className="!text-base !text-slate-700 2xl:!text-xl">
        {testimonialText}
      </Copy>
    </Card>
  );
};
