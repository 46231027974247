import React from 'react';
import classNames from 'classnames';
import { Card, Copy } from '#components/atoms';
import { CollapsibleT } from '#interfaces/components';

export const CollapsibleContainer: React.FC<CollapsibleT> = ({
  title,
  description,
  isOpen,
  onToggle,
}) => {
  return (
    <Card
      className={classNames(
        'rounded-md flex flex-col bg-white transition-all ease-in-out',
        {
          'shadow-lg': isOpen,
        },
      )}
      action={onToggle}
    >
      <Copy id="item" className="!text-base !font-bold">
        {title}
      </Copy>
      {isOpen && (
        <Copy className="transition-all ease-in-out">{description}</Copy>
      )}
    </Card>
  );
};
