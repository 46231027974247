import { useNavigate } from 'react-router-dom';

export const useScrollNavigation = () => {
  const navigate = useNavigate();

  const handleNavigation = (url: string) => {
    const [_url, sectionId] = url?.includes('#') ? url.split('#') : [];
    navigate(url);
    setTimeout(() => {
      const element = document.getElementById(sectionId);
      element && element.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };

  return handleNavigation;
};
