import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import {
  Button,
  Card,
  Carousel,
  CarouselContent,
  CarouselItem,
  Copy,
  Icons,
  Image,
  Title,
} from '#components/atoms';
import { stories } from '#mocks/stories';
import { useDeviceDetector } from '../../../hooks';

export const TestimonialsHero = () => {
  const { MobileView, DesktopView, TabletView } = useDeviceDetector();

  return (
    <div className="lg:col-span-7 lg:col-start-6 lg:grid lg:grid-cols-6 w-full lg:gap-5">
      <MobileView>
        <Carousel>
          <CarouselContent>
            {stories.map((story: any, index: number) => (
              <CarouselItem key={index} className="basis-3/4">
                <ItemCard
                  classnames="flex-col !p-0 rounded-md m-5"
                  img={story.img}
                  title={story.title}
                  description={story.description}
                  id={story.id}
                  key={index}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </MobileView>
      <TabletView>
        <div className="grid grid-cols-2 px-2 gap-5">
          {stories.map((story: any, index: number) => (
            <ItemCard
              img={story.img}
              title={story.title}
              description={story.description}
              id={story.id}
              key={index}
            />
          ))}
        </div>
      </TabletView>
      <DesktopView>
        {stories.map((story: any, index: number) => {
          const topClasses = ['-top-0', 'top-6', 'top-0', 'top-6'];

          return (
            <ItemCard
              classnames={topClasses[index]}
              img={story.img}
              title={story.title}
              description={story.description}
              id={story.id}
              key={index}
            />
          );
        })}
      </DesktopView>
    </div>
  );
};

const ItemCard = ({
  classnames,
  img,
  title,
  description,
  id,
}: {
  classnames?: string;
  img: string;
  title: string;
  description: string;
  id: string;
}) => {
  const navigate = useNavigate();

  return (
    <Card
      className={classNames(
        'relative flex-col !p-0 rounded-md col-span-3 min-w-[260px] max-h-[376px] shadow-lg',
        classnames,
      )}
    >
      <Image
        width={100}
        height={100}
        src={require(`#assets/succes-stories/${img}.webp`)}
        className="w-full rounded-t-md h-44 max-h-[134px]"
        alt="story"
      />
      <div className="-mt-2 p-5 rounded-md bg-white max-h-[250px]">
        <Title className="mb-4 !text-slate-900">{title}</Title>
        <Copy className="!text-xs !text-slate-500 mb-4">{description}</Copy>
        <Button
          modifier="expansion"
          asChild
          onClick={() => navigate(`/testimonials/${id}`)}
        >
          <p className="text-blue-500 text-left text-base font-regular whitespace-nowrap group-hover:font-bold pr-4 py-2">
            Ver más
          </p>
          <Icons
            className="text-blue-500 h-4 font-regular group-hover:stroke-[3]"
            type="outlineArrowRight"
          />
        </Button>
      </div>
    </Card>
  );
};
