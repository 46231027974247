import React from 'react';

export const useDeviceDetector = () => {
  const [deviceType, setDeviceType] = React.useState('desktop');

  React.useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setDeviceType('mobile');
      } else if (width >= 768 && width < 1024) {
        setDeviceType('tablet');
      } else {
        setDeviceType('desktop');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const MobileView = ({ children }: any) => {
    return deviceType === 'mobile' ? children : null;
  };
  const TabletView = ({ children }: any) => {
    return deviceType === 'tablet' ? children : null;
  };
  const DesktopView = ({ children }: any) => {
    return deviceType === 'desktop' ? children : null;
  };

  return { MobileView, DesktopView, TabletView, deviceType };
};

export const useSystemOpDevice = () => {
  const [deviceType, setDeviceType] = React.useState<string>('standard');

  React.useEffect(() => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      setDeviceType('android');
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setDeviceType('ios');
    } else {
      setDeviceType('standard');
    }
  }, []);

  return deviceType;
};
