import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Carousel,
  CarouselContent,
  CarouselItem,
  Copy,
  Icons,
  Image,
  Title,
} from '#components/atoms';
import { StackTestimonials } from '#components/organisms';
import { stories } from '#mocks/stories';
import { extendTestimonials } from '#mocks/extend-testimonials';
import { useDeviceDetector } from '../../hooks';

export const ExtendTestimonial = () => {
  const { id }: any = useParams();
  const { MobileView, DesktopView } = useDeviceDetector();
  const navigate = useNavigate();
  const {
    header,
    title,
    first_item,
    detail_testimonial,
    extend_story,
    more_details,
    img,
  } = extendTestimonials[id] || extendTestimonials['alvarado'];

  return (
    <div className="max-w-screen-2xl mx-auto px-5 lg:px-20 py-24">
      <Copy className="!text-base lg:!text-xl !font-bold !text-blue-400 mb-2 lg:text-start">
        {header}
      </Copy>
      <Title className="text-4xl mb-14 lg:text-start lg:text-5xl">
        {title}
      </Title>
      <Image
        src={require(`#assets/succes-stories/details/${img}.webp`)}
        alt="device-frame"
        className="w-full object-fill md:!h-80"
      />
      <CardItem>{first_item}</CardItem>
      {[detail_testimonial, extend_story, more_details].map((item, index) => (
        <CardDetail img={item.img} key={index}>
          {item.content}
        </CardDetail>
      ))}
      <Copy className="!text-base lg:!text-5xl !font-bold !text-blue-400 lg:!text-slate-700 mb-4 lg:text-center lg:mb-7 lg:mt-14">
        Sigue viendo mas historias
      </Copy>
      <MobileView>
        <Carousel>
          <CarouselContent>
            {stories.map((story: any, index: number) => (
              <CarouselItem key={index} className="basis-3/4">
                <Card
                  className="flex-col !p-0 rounded-md mb-4 !pb-12"
                  key={index}
                >
                  <Image
                    width={100}
                    height={100}
                    src={require(`#assets/succes-stories/${story.img}.webp`)}
                    className="w-full rounded-t-md h-44"
                    alt="story"
                  />
                  <div className="-mt-2 p-5 rounded-md bg-white shadow-md">
                    <Title className="text-lg mb-4 !text-slate-900">
                      {story.title}
                    </Title>
                    <Copy className="text-sm !text-slate-500 mb-4">
                      {story.description}
                    </Copy>
                    <Button
                      modifier="expansion"
                      asChild
                      onClick={() => navigate(`/testimonials/${story.id}`)}
                    >
                      <p className="text-blue-500 text-left text-base font-regular whitespace-nowrap group-hover:font-bold pr-4 py-2">
                        Ver más
                      </p>
                      <Icons
                        className="text-blue-500 h-4 font-regular group-hover:stroke-[3]"
                        type="outlineArrowRight"
                      />
                    </Button>
                  </div>
                </Card>
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </MobileView>
      <DesktopView>
        <StackTestimonials />
      </DesktopView>
    </div>
  );
};

const CardItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <Card className="bg-blue-50 !p-8 rounded-md shadow-md my-14 md:my-10">
      <Copy className="!text-lg lg:!text-xl">{children}</Copy>
    </Card>
  );
};

const CardDetail = ({
  children,
  img,
}: {
  children: React.ReactNode;
  img: string;
}) => {
  return (
    <div className="flex flex-col pb-10 lg:grid lg:grid-cols-12 lg:gap-x-6">
      {img && (
        <Image
          src={require(`#assets/succes-stories/details/${img}.webp`)}
          alt="device-frame"
          className="w-full object-cover !h-36 mb-14 lg:mb-0 lg:col-span-6 lg:!h-[355px] lg:!w-[530px] lg:rounded-md"
        />
      )}
      {children}
    </div>
  );
};
