import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

const container = document.getElementById('main');
const root = createRoot(container as HTMLElement);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
