import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MobilMenu, DesktopMenu, Logo } from '#components/molecules';

export const Navigation = () => {
  const [scrolling, setScrolling] = React.useState(true);
  const navigate = useNavigate();
  const handleScroll = () => {
    setScrolling(window.scrollY > 10);
  };
  window && (window.onscroll = handleScroll);

  return (
    <div
      className={`fixed top-0 left-0 right-0 z-50 border-b flex w-auto justify-between lg:flex !p-3 !px-5 lg!px-24 transition-colors ease-in-out shadow-lg ${
        scrolling ? 'bg-white border-none' : 'bg-transparent'
      }`}
    >
      <div className="max-w-screen-2xl mx-auto w-full justify-between flex lg:px-20 items-center">
        <Logo
          width={100}
          height={100}
          classname="h-6 w-16 lg:mr-24 cursor-pointer max-w-screen-2xl lg:mx-auto md:h-10 md:w-24"
          onclick={() => navigate('/')}
        />
        <MobilMenu />
        <DesktopMenu />
      </div>
    </div>
  );
};
